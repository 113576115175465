<template>
  <tr>
    <td></td>
    <td @click="openDialog">
      {{ getSelectedClient }}
    </td>
    <td @click="openDialog" v-if="task.ref_num !== null">
      {{ task.created_at.substring(2, 4) + "_" + task.ref_num }}
    </td>
    <td @click="openDialog" v-else>-</td>
    <td @click="openDialog">{{ task.topic }}</td>
    <td @click="openDialog">{{ getAccount }}</td>
    <td @click="openDialog">{{ createdAt }}</td>
    <td>
      <div v-if="$store.getters.isAdmin" class="restore-button">
        <button @click="restoreTask(task.uuid)">
          <i class="icon-Check" />Przywróć
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import api from "../api";
import { mapActions } from "vuex";

export default {
  name: "ArchivedTask",
  components: {},
  props: {
    task: Object,
  },
  data() {
    return {};
  },
  computed: {
    createdAt() {
      const date = this.task.created_at;
      return date.split("T").shift();
    },
    getSelectedClient() {
      if (this.task.corporate_client_id) {
        const client = this.$store.state.corporateClients.find(
          (el) => el.id === this.task.corporate_client_id
        );
        return client ? client.name : "";
      } else {
        return "";
      }
    },
    getAccount() {
      if (this.task.account_id) {
        const account = this.$store.state.users.find(
          (el) => el.id === this.task.account_id
        );
        return account ? account.name : "";
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions({
      snack: "snack/snack",
      side: "snack/side",
    }),
    openDialog() {
      this.$emit("openDialog");
    },
    restoreTask(uuid) {
      api
        .restoreTask(uuid)
        .then(() => {
          this.$emit("restoreTask");
          this.snack({
            text: `Zadanie "${this.task.topic}" zostało przywrócone`,
            severity: "info",
          });
        })
        .catch((error) => {
          console.error(error);
          this.snack({
            text: `Wystąpił błąd" ${error.response.data.message}`,
            severity: "error",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
.restore-button {
  padding: 0;
  display: flex;
  align-content: center;
}

button {
  display: flex;
  border: none;
  background-color: $primary;
  color: $white;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  i {
    color: $white;
    margin-right: 4px;
    font-size: 16px;
  }
}

tr {
  position: relative;
  cursor: pointer;
  td {
    &:nth-child(1),
    &:nth-child(3) {
      font-weight: 600;
    }
  }
}
</style>
