<template>
  <span v-if="s" class="task_status_label" :class="[s.status,  error && s.status === 'qa' ? 'error' : '']" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-delay="500" data-bs-placement="top" data-bs-html="true" :data-bs-content="s.gif ? '<img src=\''+s.gif+'\'/>' : ''" ref="gif">
    <i v-if="s.icon" :class="s.icon" />
    {{ s.name }}
  </span>
  <p v-else class="task_status_label">-</p>
</template>

<script>
const bootstrap = require('bootstrap')
const taskStatuses = [
  {
    status: null,
    name: "-"
},
{
    status: "corporate_client_qa",
    name: "Po stronie klienta",
    icon: 'icon-Office',
    gif: 'https://media.giphy.com/media/bupsZiBKn7vAk/giphy.gif',
},
{
    status: "qa",
    name: "Po stronie akanta",
    gif: 'https://media.giphy.com/media/9NEH2NjjMA4hi/giphy.gif',
},
{
    status: "required_more_data",
    name: "Brak materiałów",
    icon: 'icon-Fire',
    gif: 'https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy.gif'
},
{
    status: "pending",
    name: "Korekta",
    icon: 'icon-Clock',
    gif: 'https://media1.tenor.com/images/640995c4171bf572d694f833689b14b0/tenor.gif',
},
{
    status: "done",
    name: "Zakończone"
},
  {
    status: 'in_work',
    name: 'W trakcie pracy',
    gif: 'https://media.giphy.com/media/5Zesu5VPNGJlm/giphy.gif',
  },
  {
    status: 'pause',
    name: 'Wstrzymano',
    gif: 'https://media.giphy.com/media/bTmU4fa3xUyqY/giphy.gif',
  },
]

export default {
name: "TaskStatusLabel",
  props: {
    status: String,
    gif: {type: Boolean, default: false},
    error: {type: Boolean, default: false}
  },
  data(){
    return {
      taskStatuses: taskStatuses
    }
  },
  mounted() {
    if(this.gif && this.s.gif){
      new bootstrap.Popover(this.$refs.gif, {})
    }
  },
  computed: {
    s: function (){
      return this.taskStatuses.find(x => x.status === this.status)
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.error {
  color: $danger;
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.task_status_label{
  display: inline-block;
  padding: 0.35em 0.65em;
  margin: 4px;
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 6px;
  i {
    font-size: 16px;
    padding-right: 4px;
  }
  &.pending {
    background-color: lighten($warning, 45%);
    color: $warning;
  }
  &.required_more_data, &.pause {
    background-color: lighten($danger, 50%);
    color: $danger;
  }
  &.in_work {
    background-color: lighten($secondary, 30%);
    color: $secondary;
  }
}
</style>
