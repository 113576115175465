<template>
  <app-wrapper>
    <page-header title="Tabelka prac"></page-header>
    <div class="container">
      <task-filters></task-filters>
<!--      <h1 v-if="!user" class="login-info">NIE JESTEŚ ZALOGOWANY :)</h1>-->
      <div v-if="user">
        <div v-for="u in $store.getters.filteredUsers" :key="u.id" class="row">
          <div class="col">
            <user-tasks-edit v-if="$store.getters.isAdmin || $store.getters.isAccount" :init-user="u" :task-id="taskId"/>
            <user-tasks v-else :user="u" :task-id="taskId"/>
          </div>
        </div>
        <div class="overlay" v-if="$store.getters.filteredUsers.length === 0 && $store.getters.getTasks.length === 0">
          <div>
            <div
              class="spinner-border text-light"
              style="width: 5rem; height: 5rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import AppWrapper from "@/components/AppWrapper";
import PageHeader from "@/components/PageHeader";
import UserTasksEdit from "@/components/UserTasksEdit";
import UserTasks from "@/components/UserTasks";
import TaskFilters from "@/components/TaskFilters";
import {mapState} from 'vuex'

export default {
  props: {
    taskId: String,
  },
  name: "Home",
  components: {TaskFilters, UserTasks, UserTasksEdit, PageHeader, AppWrapper},
  mounted() {
    this.$store.dispatch('downloadTasks')
  },
  computed: {
    ...mapState(['user'])
  },
}
</script>

<style scoped>
.login-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8rem;
  color: red;
}
.overlay {
  position: fixed;
  inset: 0;
  z-index: 6000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
