<template>
  <div class="bg-white header">
    <div class="container py-4">
      <div class="row">
        <div class="col ">
          <h1 class="px-5">{{ title }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: String,
  }
}
</script>

<style scoped>
.header {
  margin-left: -78px;
}
</style>