<template>
  <app-wrapper>
    <page-header title="Archiwum"></page-header>
    <div class="container mt-3 content">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              Archiwum zadań
            </div>
            <div class="card-body">
              <form class="form">
                <div class="row">
                  <div>
                    <div class="input-group mb-3">
                      <span class="input-group-text">Klient</span>
                      <select class="form-select" v-model="client">
                        <option
                          v-for="client in $store.getters.sortedClients"
                          :key="client.id"
                          :value="client.id"
                        >
                          {{ client.name }}
                        </option></select
                      >
                      <span class="input-group-text ms-3">Numer zadania</span>
                      <input
                          class="form-control small-field"
                          placeholder=""
                          type="number"
                          v-model="refNum.year"
                      />
                      <span class="input-group-text">_</span>
                      <input
                          class="form-control small-field"
                          placeholder=""
                          type="number"
                          v-model="refNum.ref"
                      />

                      <span class="input-group-text ms-3">Data utworzenia</span>
                      <input
                        class="form-control"
                        placeholder="od"
                        type="text"
                        onfocus="(this.type='date')"
                        v-model="from"
                      />
                      <input
                        class="form-control"
                        type="text"
                        onfocus="(this.type='date')"
                        placeholder="do"
                        v-model="to"
                      />
                    </div>
                  </div>
                  <div class="button">
                    <button
                      class="btn btn-primary ms-1"
                      @click="search"
                      :disabled="refreshing"
                    >
                      <span
                        v-if="refreshing"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-if="refreshing"> Szukam</span>
                      <span v-if="!refreshing">Szukaj</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-if="pageNumber <= lastPage && lastPage > 1" class="button mt-5">
          <button
            :class="{ disabled: pageNumber <= 1 }"
            :disabled="pageNumber <= 1"
            class="btn btn-primary ms-1"
            @click="getArchivedTasks((pageNumber -= 1))"
          >
            <i class="icon-Arrow-Left" />
          </button>
          <label class="mx-3">{{ pageNumber }} / {{ lastPage }}</label>
          <button
            :class="{ disabled: pageNumber >= lastPage }"
            :disabled="pageNumber >= lastPage"
            class="btn btn-primary ms-1"
            @click="getArchivedTasks((pageNumber += 1))"
          >
            <i class="icon-Arrow-Right" />
          </button>
        </div>
        <div v-if="tasks.length > 0">
          <archived-tasks
            :tasks="tasks"
            :getArchivedTasks="getArchivedTasks"
            :pageNumber="pageNumber"
          ></archived-tasks>
        </div>
        <div v-if="pageNumber <= lastPage && lastPage > 1" class="button">
          <button
            :class="{ disabled: pageNumber <= 1 }"
            :disabled="pageNumber <= 1"
            class="btn btn-primary ms-1"
            @click="getArchivedTasks((pageNumber -= 1))"
          >
            <i class="icon-Arrow-Left" />
          </button>
          <label class="mx-3">{{ pageNumber }} / {{ lastPage }}</label>
          <button
            :class="{ disabled: pageNumber >= lastPage }"
            :disabled="pageNumber >= lastPage"
            class="btn btn-primary ms-1"
            @click="getArchivedTasks((pageNumber += 1))"
          >
            <i class="icon-Arrow-Right" />
          </button>
        </div>
        <div class="resault-info">
          <h2 v-if="emptyTasks === true">
            Nie znaleziono zadań dla wybranego
            Klienta
          </h2>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import PageHeader from "../components/PageHeader";
import AppWrapper from "../components/AppWrapper";
import ArchivedTasks from "@/components/ArchivedTasks";
import api from "@/api";
import { mapActions } from "vuex";

export default {
  name: "Archive",
  components: { PageHeader, AppWrapper, ArchivedTasks },
  data() {
    return {
      client: null,
      from: null,
      to: null,
      tasks: [],
      pageNumber: 1,
      lastPage: null,
      emptyTasks: false,
      refreshing: false,
      refNum: {
        year: null,
        ref: null,
      }
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      snack: "snack/snack",
    }),
    search() {
      this.pageNumber = 1;
      this.getArchivedTasks(this.pageNumber);
    },
    getArchivedTasks(page) {
      this.emptyTasks = false;
      this.tasks = [];
      const data = {
        corporate_client_id: this.client,
        ref_num: this.refNum.ref,
        ref_year: this.refNum.year,
        from_date: this.from,
        to_date: this.to,
      };
      if (!this.client) {
        this.snack({
          text: `Pole Klient jest wymagane`,
          severity: "error",
        });
      } else if ((this.refNum.ref && !this.refNum.year) || (!this.refNum.ref && this.refNum.year)) {
        this.snack({
          text: `Wymagany jest cały numer zadania albo jego brak`,
          severity: "error",
        });
      } else if (!this.refNum.ref && !this.from) {
        this.snack({
          text: `Pole daty "od" jest wymagane`,
          severity: "error",
        });
      } else if (!this.refNum.ref && !this.to) {
        this.snack({
          text: `Pole daty "do" jest wymagane`,
          severity: "error",
        });
      } else {
        this.refreshing = true;
        api
          .getArchived(data, page)
          .then((response) => {
            if (response.data.total === 0) {
              this.emptyTasks = true;
            } else if (response.data.total > 0) {
              this.emptyTasks = false;
            }
            this.tasks = response.data.data;
            this.lastPage = response.data.last_page;
          })
          .catch((error) => {
            console.error(error);
            this.snack({
              text: `Wystąpił błąd: ${error.response.data.message}`,
              severity: "error",
            });
          })
          .finally(() => {
            this.refreshing = false;
          });
      }
    },
  },
};
</script>

<style>
.small-field{
  max-width: 60px;
}
.resault-info {
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  text-align: center;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}
</style>
