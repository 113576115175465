<template>
  <div :class="getClasses()">
    <div
      class="toast"
      :class="snack !== null && 'show'"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header header" :class="getHeaderColorClass()">
        <strong class="mr-auto">{{ getHeaderText() }}</strong>
      </div>
      <div class="toast-body">
        {{ snack }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      snack: "snack/snack",
      severity: "snack/severity",
      side: "snack/side",
    }),
  },
  methods: {
    getClasses() {
      return {
        'snackbar': true,
        'background-right': this.side === 'right',
        'background-left': this.side === 'left',
        'on-top': this.snack !== null,
      }
    },
    getHeaderColorClass() {
      switch (this.severity) {
        case "warning":
          return "header-yellow";
        case "error":
          return "header-red";
        default:
          return "header-blue";
      }
    },
    getHeaderText() {
      switch (this.severity) {
        case "warning":
          return "Ostrzeżenie";
        case "error":
          return "Błąd";
        default:
          return "Info";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.snackbar {
  position: fixed;
  bottom: 50px;
}

.on-top {
  z-index: 5000;
}

.background-right {
  right: 50px;
}

.background-left {
  left: 50px;
}

.header-blue {
  background-color: $primary;
  color: white;
}

.header-red {
  background-color: $danger;
  color: white;
}

.header-yellow {
  background-color: $warning;
  color: white;
}
</style>
