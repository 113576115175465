import { createRouter, createWebHashHistory } from "vue-router";
import Authorization from "@/views/Authorization";
import Home from "@/views/Home";
import CorporateClients from "@/views/CorporateClients";
import Reports from "@/views/Reports";
import Archive from "@/views/Archive";
import UsersEdit from "@/views/UsersEdit";
import ResetPassword from "@/components/ResetPassword.vue";

const routes = [
  {
    path: "/login",
    name: "Authorization",
    component: Authorization,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    props: (route) => ({ taskId: route.query.taskId }),
    meta: { requiresAuth: true }
  },
  {
    path: "/users",
    name: "UsersEdit",
    component: UsersEdit,
    meta: { requiresAuth: true }
  },
  {
    path: "/corporate-clients",
    name: "CorporateClients",
    component: CorporateClients,
    meta: { requiresAuth: true }
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: { requiresAuth: true }
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
    meta: { requiresAuth: true }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/login'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!localStorage.getItem('auth_token');
  if (to.path === '/login' && loggedIn) {
    next('/home');
  } else if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
