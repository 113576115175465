export default {
  namespaced: true,

  state: {
    snack: null,
    severity: "info",
    side: "left",
  },

  getters: {
    snack(state) {
      return state.snack;
    },
    severity(state) {
      return state.severity;
    },
    side(state) {
      return state.side;
    },
  },
  mutations: {
    SET_SNACK(state, { text, severity, side }) {
      state.snack = text;
      state.severity = severity;
      state.side = side;
    },
  },

  actions: {
    snack({ commit }, { text, severity, side }) {
      commit("SET_SNACK", { text, severity, side });

      setTimeout(() => {
        commit("SET_SNACK", { text: null, severity: "info" });
      }, 5000);
    },
  },
};
