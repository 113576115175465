<template>
  <div v-if="isDialogOpen" class="modal dialog-background show">
    <div
      class="modal-dialog dialog modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header modal-padding">
          <h5 class="modal-title">{{ task ? "Zadanie" : "Nowe zadanie" }}</h5>
        </div>
        <div class="modal-body modal-padding">
          <div class="row justify-content-between">
            <div class="col-9" style="position: relative">
              <span
                v-if="!readOnly && !validateTopic(t.topic)"
                class="topic-count"
                >{{ charCount(t.topic) }}/40</span
              >
              <label
                ><i class="icon-Paragraph" />&nbsp;Temat&nbsp;<span
                  v-if="!readOnly"
                  >(max. 40 znaków)</span
                ></label
              >
              <input
                class="form-control"
                :class="validateTopic(t.topic)"
                :disabled="
                  readOnly ||
                    (visable && !$store.getters.isAdmin) ||
                    (task && !$store.getters.isAdmin)
                "
                v-model="t.topic"
                placeholder="Temat zadania"
                maxlength="40"
              />
              <label><i class="icon-Office" />&nbsp;Klient</label>
              <select
                class="form-control"
                :disabled="
                  readOnly ||
                    (visable && !$store.getters.isAdmin) ||
                    (task && !$store.getters.isAdmin)
                "
                v-model="t.corporate_client_id"
                ref="client_select"
                :class="validateCorporateClient(t.corporate_client_id)"
              >
                <option
                  :value="cc.id"
                  v-for="cc in $store.getters.sortedClients"
                  :key="'cc_' + cc.id"
                >
                  {{ cc.name }}
                </option>
              </select>
              <label><i class="icon-Comment" />&nbsp;Opis Zadania</label>
              <textarea
                class="form-control"
                :class="validateDescription(t.description)"
                rows="3"
                :disabled="readOnly"
                v-model="t.description"
                placeholder="Opis Zadania"
              />
            </div>
            <div class="col-3">
              <label><i class="icon-Flag" /> Status</label>
              <select
                class="form-control"
                v-model="t.status"
                :disabled="readOnly"
              >
                <option value="">-</option>
                <option value="corporate_client_qa">Po stronie klienta</option>
                <option value="qa">Po stronie akanta</option>
                <option value="required_more_data">Brak materiałów</option>
                <option v-if="t.id" value="pending">Korekta</option>
                <option v-if="t.id" value="done">Zakończone</option>
              </select>
              <label><i class="icon-Person" />Account</label>
              <select
                v-if="$store.getters.isAdmin"
                class="form-control"
                v-model="t.account_id"
                :disabled="readOnly"
              >
                <option
                  :value="account.id"
                  v-for="account in $store.getters.accountUsers"
                  :key="'account_' + account.id"
                >
                  {{ account.name }}
                </option>
              </select>
              <select
                v-else
                class="form-control"
                v-model="t.account_id"
                disabled
              >
                <option
                  v-for="account in $store.getters.accountUsers"
                  :key="'account_' + account.id"
                  :value="account.id"
                >
                  {{ account.name }}
                </option>
              </select>
              <label><i class="icon-Person" />Grafik</label>
              <select
                v-if="$store.getters.isAdmin"
                class="form-control"
                v-model="t.employee_id"
                :disabled="readOnly"
              >
                <option
                  :value="graphics.id"
                  v-for="graphics in $store.getters.graphicsUSers"
                  :key="'account_' + graphics.id"
                >
                  {{ graphics.name }}
                </option>
              </select>
              <select
                v-else
                class="form-control"
                v-model="t.employee_id"
                disabled
              >
                <option
                  v-for="graphics in $store.getters.graphicsUSers"
                  :key="'account_' + graphics.id"
                  :value="graphics.id"
                >
                  {{ graphics.name }}
                </option>
                ></select
              >
              <label for="deadline_dialog_input">
                <i class="icon-Calendar" />
                Deadline
              </label>
              <input
                v-model="t.deadline"
                type="datetime-local"
                :disabled="readOnly"
                class="deadline-input form-control"
                :class="validateDeadline(t.deadline)"
                id="deadline_dialog_input"
                :min="minDate"
              />
              <label v-if="t.created_at">
                <i class="icon-Clock" />
                Utworzono
              </label>
              <span v-if="t.created_at" class="form-control">{{
                displayCreatedAt(t.created_at)
              }}</span>
              <label for="estimation_dialog_input">
                <i class="icon-Clock" />
                Estymacja
              </label>
              <input
                id="estimation_dialog_input"
                v-model="t.estimation"
                type="time"
                :disabled="isDisabledEstimation()"
                class="estimation-input form-control"
                :class="validateEstimation(t.estimation)"
                placeholder="HH:MM"
              />
              <button v-if="t.work_logs && t.work_logs.length && canViewHistory()" class="btn btn-primary btn-center" @click="openHistoryDialog">Historia</button>
            </div>
          </div>
          <div class="mt-5" v-show="visable">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label>
                  <OpenFolderIcon />
                  Wybierz folder (wybrany folder podświetla się na
                  niebiesko)</label
                >
                <div class="files__box">
                  <div
                    class="file__option"
                    :class="{ select: isChecked('materials') }"
                  >
                    <input
                      @change="filesTabHandler"
                      type="radio"
                      name="materials"
                      id="materials"
                      value="materials"
                      v-model="filesType"
                    />
                    <label for="materials">Materiały</label>
                  </div>
                  <div
                    class="file__option"
                    :class="{ select: isChecked('draft') }"
                    v-if="
                      !$store.getters.isAccount ||
                        $store.state.user.type === 'admin'
                    "
                  >
                    <input
                      @change="filesTabHandler"
                      type="radio"
                      name="draft"
                      id="draft"
                      value="draft"
                      v-model="filesType"
                    />
                    <label for="draft">Robocze</label>
                  </div>
                  <div
                    class="file__option"
                    :class="{ select: isChecked('projects') }"
                  >
                    <input
                      @change="filesTabHandler"
                      type="radio"
                      name="projects"
                      id="projects"
                      value="projects"
                      v-model="filesType"
                    />
                    <label for="projects">Projekt</label>
                  </div>
                  <div
                    class="file__option"
                    :class="{ select: isChecked('final') }"
                  >
                    <input
                      @change="filesTabHandler"
                      type="radio"
                      name="final"
                      id="final"
                      value="final"
                      v-model="filesType"
                    />
                    <label for="final">Final</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="file-info">
              <strong class="pe-2">Lokalizacja:</strong>{{ currentPath }}
            </div>
            <div class="file-info">
              <strong class="pe-2">Nazwa Zadania:</strong>{{ generateTaskName }}
            </div>
            <div v-if="currentPath" class="toolbar">
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Kopiuj ścieżkę dla Windows"
              >
                <button
                  class="toolbar-button"
                  @click="copyPathToClipboardWindows(currentPath)"
                >
                  Otwórz Windows <WindowsIcon />
                </button>
              </span>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Kopiuj ścieżkę dla Mac"
              >
                <button
                  class="toolbar-button"
                  @click="copyPathToClipboardMac(currentPath)"
                >
                  Otwórz Mac <AppleIcon />
                </button>
              </span>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Udostępnij"
              >
                <button class="toolbar-button" @click="getShareLink(rootPath)">
                  Udostępnij <ShareIcon />
                </button>
              </span>
              <span
                data-toggle="tooltip"
                data-placement="top"
                title="Eksportuj do Excel"
              >
                <button class="toolbar-button" @click="downloadFile()">
                  Pobierz listę plików <ExcelIcon />
                </button>
              </span>
              <span title="Kopiuj nazwę zadania">
                <button class="toolbar-button" @click="copyToClipboard()">
                  Skopiuj nazwę zadania <CopyToClipboard />
                </button>
              </span>
            </div>

            <table class="table table-striped files-table">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">Nazwa</th>
                  <th scope="col">Autor</th>
                  <th scope="col">Data modyfikacji</th>
                  <th colspan="3" scope="col" style="text-align: center;">
                    Otwórz
                  </th>
                  <th scope="col" style="text-align: center;">Udostępnij</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="currentPath !== rootPath">
                  <td colspan="4"></td>
                  <td>
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Wróć"
                    >
                      <button
                        v-if="
                          !loadingSubfolder.active &&
                            loadingSubfolder.index === null
                        "
                        class="btn"
                        @click="goBack(currentPath)"
                      >
                        <i class="icon-Arrow-Left" />&nbsp; Wróć
                      </button>
                      <div
                        v-if="
                          loadingSubfolder.active &&
                            loadingSubfolder.index === null
                        "
                        class="spinner-border spinner-border-sm"
                      />
                    </span>
                  </td>
                  <td colspan="3"></td>
                </tr>
                <tr v-for="(file, index) in filteredFiles" :key="index">
                  <th>{{ index + 1 }}</th>
                  <td class="file-name">
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="file.name"
                    >
                      {{ file.name }}
                    </span>
                  </td>
                  <td v-if="!file.isdir">
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="file.additional.owner.user"
                    >
                      {{ file.additional.owner.user }}
                    </span>
                  </td>
                  <td v-else></td>
                  <td v-if="!file.isdir">
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="
                        toLocaleDateTimeString(file.additional.time.mtime)
                      "
                    >
                      {{ toLocaleDateTimeString(file.additional.time.mtime) }}
                    </span>
                  </td>
                  <td v-else></td>
                  <td v-if="!file.isdir" />
                  <td v-else>
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Otwórz folder"
                    >
                      <button
                        v-if="!loadingSubfolder.active"
                        class="btn"
                        @click="openSubFolder(file.path, index)"
                      >
                        <OpenFolderIcon />
                      </button>
                      <span
                        v-if="
                          loadingSubfolder.active &&
                            loadingSubfolder.index === index
                        "
                        class="spinner-border spinner-border-sm"
                      ></span>
                    </span>
                  </td>
                  <td>
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Kopiuj ścieżkę dla Windows"
                    >
                      <button
                        class="btn"
                        @click="copyPathToClipboardWindows(file.path)"
                      >
                        <WindowsIcon />
                      </button>
                    </span>
                  </td>
                  <td>
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Kopiuj ścieżkę dla Mac"
                    >
                      <button
                        class="btn"
                        @click="copyPathToClipboardMac(file.path)"
                      >
                        <AppleIcon />
                      </button>
                    </span>
                  </td>
                  <td style="text-align: center;">
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Udostępnij"
                    >
                      <button
                        v-if="!gettingShareLink.active"
                        class="btn"
                        @click="getShareLink(file.path, index)"
                      >
                        <ShareIcon />
                      </button>
                      <span
                        v-if="
                          gettingShareLink.active &&
                            gettingShareLink.index === index
                        "
                        class="spinner-border spinner-border-sm"
                      ></span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="justify-content-center d-flex">
              <div v-if="loadingFiles" class="spinner-border" />
              <div v-if="!loadingFiles && displayedFiles.length === 0">
                Brak plików do wyświetlenia
              </div>
            </div>
          </div>
        </div>
        <Popup :open="isOpen">
          <div>
            <h5>
              Czy na pewno wszystkie dane uzupełniłeś poprawnie?<br />
              Sprawdź czy są literówki :)
            </h5>
            <div class="data-margin">
              <span><strong>Temat:</strong> {{ t.topic }}</span
              ><br />
              <span><strong>Klient:</strong> {{ getSelectedClient }}</span>
            </div>
            <div class=" d-flex justify-content-center align-items-center">
              <button
                class="btn btn-primary"
                @click="handleSave"
                :disabled="loading"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="loading"> Zapisywanie...</span>
                <span v-if="!loading">TAK</span>
              </button>
              <button class="btn btn-secondary m-2" @click="isOpen = !isOpen">
                POPRAWIAM
              </button>
            </div>
          </div>
        </Popup>
        <div class="modal-footer modal-padding py-3">
          <button
            v-if="t.id && t.status !== 'done'"
            class="btn btn-primary ms-1"
            @click="refresh(t)"
            :disabled="refreshing"
          >
            <span
              v-if="refreshing"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="refreshing"> Odświeżam</span>
            <span v-if="!refreshing"><RefreshIcon /> Odśwież</span>
          </button>
          <button
            v-if="t.id && t.status !== 'done'"
            class="btn btn-secondary me-auto"
            @click="sendNotification()"
            :disabled="sending"
          >
            <span
              v-if="sending"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="sending"> Wysyłanie</span>
            <span v-if="!sending"> <BellIcon /> Powiadom</span>
          </button>
          <button
            v-if="t.id && t.status == 'done' && $store.getters.isAdmin"
            class="btn btn-primary me-auto"
            @click="restoreTask(task.uuid)"
          >
            <i class="icon-Check" />
            Przywróć
          </button>
          <button
            v-if="!readOnly && visable"
            class="btn btn-primary"
            @click="handleSave"
            :disabled="loading"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="loading"> Zapisywanie...</span>
            <span v-if="!loading">Zapisz</span>
          </button>
          <button
            v-if="!visable"
            class="btn btn-primary"
            @click="isOpen = true"
          >
            Dalej
          </button>
          <button class="btn btn-secondary" @click="close">Zamknij</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isHistoryDialogOpen" class="modal dialog-background show">
    <div
      class="modal-dialog dialog modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header modal-padding">
          <h5 class="modal-title">Historia czasu zadania</h5>
          <button type="button" class="close" @click="closeHistoryDialog">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-padding">
          <div v-if="t.work_logs.length">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Lp.</th>
                <th scope="col">Początek</th>
                <th scope="col">Koniec</th>
                <th scope="col">Użytkownik</th>
                <th scope="col">Czas trwania</th>
                <th scope="col" v-if="$store.getters.isAdmin">Akcje</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(log, index) in t.work_logs" :key="log.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>
                  <span v-if="!log.isEditing">{{ formatTime(log.start) }}</span>
                  <input v-else type="datetime-local" v-model="log.start" />
                </td>
                <td>
                  <span v-if="!log.isEditing">{{ log.end ? formatTime(log.end, true) : '--:--' }}</span>
                  <input v-else type="datetime-local" v-model="log.end" />
                </td>
                <td>{{ log.employee.name  ? log.employee.name : 'Unknown' }}</td>
                <td>{{ log.end ? calculateDuration(log.start, log.end) : 'N/A' }}</td>
                <td class="action-box" v-if="$store.getters.isAdmin">
                  <button class="btn btn-primary" v-if="!log.isEditing" @click="editLog(log, true)">Edytuj</button>
                  <button class="btn btn-primary" v-if="log.isEditing" @click="saveLog(log)">Zapisz</button>
                  <span class="btn-close" v-if="log.isEditing"  @click="editLog(log, false)"></span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="history__time">
              <p><strong>Łączny czas:</strong> {{ getTotalTime(t.work_logs) }}</p>
              <p v-if="t.estimation && t.work_logs"><strong>Pozostały czas:</strong> {{ getRemainingTime(t.estimation, t.work_logs) }}</p>
            </div>
          </div>
          <div v-else>
            Brak zalogowanego czasu.
          </div>
          <div class="estimation-history" v-if="t.estimation_histories.length">
            <h5 class="mt-4">Historia estymacji</h5>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Lp.</th>
                <th scope="col">Stara estymacja</th>
                <th scope="col">Nowa estymacja</th>
                <th scope="col">Zmieniono przez</th>
                <th scope="col">Data</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(history, index) in t.estimation_histories" :key="history.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ history.old_estimation }}</td>
                <td>{{ history.new_estimation }}</td>
                <td>{{ history.user.name }}</td>
                <td>{{ formatDate(history.created_at) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";
import {mapActions} from "vuex";
import ShareIcon from "@/icons/ShareIcon";
import OpenFolderIcon from "@/icons/OpenFolderIcon";
import WindowsIcon from "@/icons/WindowsIcon";
import AppleIcon from "@/icons/AppleIcon";
import CopyToClipboard from "@/icons/CopyToClipboard";
import RefreshIcon from "@/icons/RefreshIcon.vue";
import BellIcon from "@/icons/BellIcon.vue";
import Popup from "./Popup.vue";
import {ref} from "vue";
import ExcelExport from "export-xlsx";
import ExcelIcon from "@/icons/ExcelIcon.vue";
import moment from 'moment';

export default {
  name: "TaskDialog",
  components: {
    ExcelIcon,
    RefreshIcon,
    BellIcon,
    AppleIcon,
    WindowsIcon,
    OpenFolderIcon,
    ShareIcon,
    CopyToClipboard,
    Popup,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    task: null,
    isDialogOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    employee_id: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };
  },
  data() {
    return {
      isHistoryDialogOpen: false,
      originalLogs: {},
      t: {
        topic: null,
        employee_id: null,
        account_id: null,
        corporate_client_id: null,
        description: null,
        deadline: null,
        status: null,
        estimation: null,
      },
      loading: false,
      refreshing: false,
      sending: false,
      errors: {
        topic: false,
        corporate_client_id: false,
        description: false,
        deadline: false,
        estimation: false
      },
      filesType: "materials",
      loadingFiles: true,
      loadingSubfolder: { active: false, index: null },
      gettingShareLink: { active: false, index: null },
      displayedFiles: [],
      rootFiles: [],
      rootPath: "",
      currentPath: "",
      visable: true,
    };
  },
  mounted() {
    if (this.task) {
      if (this.task.status === "done") {
        this.t = this.task;
      } else {
        this.refreshTask(this.task.uuid);
      }
      this.refreshFiles(this.task);
      this.visable = true;
    } else {
      this.t.employee_id = this.employee_id;
      this.t.account_id = this.$store.getters.isAccount
        ? this.$store.state.user.id
        : null;
      this.visable = false;
      this.loadingFiles = false;
    }
  },
  beforeUnmount() {
    const newUrl = window.location.href.split("?").shift();
    window.location.replace(newUrl);
  },
  methods: {
    editLog(log, isOpen) {
      if(isOpen) {
        this.originalLogs[log.id] = { start: log.start, end: log.end };
        log.isEditing = true
      } else {
        log.start = this.originalLogs[log.id].start;
        log.end = this.originalLogs[log.id].end;
        log.isEditing = false;
      }
    },
    formatTime(time) {
      if (!time) {
        return '--:--';
      }
      return moment(time).startOf('minute').format('YYYY-MM-DD HH:mm');
    },
    getTotalTime(workLogs) {
      const totalDuration = workLogs.reduce((total, log) => {
        const startTime = moment(log.start);
        const endTime = moment(log.end);
        let duration = moment.duration(endTime.diff(startTime));
        if (duration.asSeconds() > 0 && duration.asSeconds() < 60) {
          duration = moment.duration(1, 'minutes');
        } else {
          duration = moment.duration(Math.ceil(duration.asMinutes()), 'minutes');
        }
        return total.add(duration);
      }, moment.duration());

      const totalMinutes = totalDuration.asMinutes();
      const hours = String(Math.floor(Math.abs(totalMinutes) / 60)).padStart(2, '0');
      const minutes = String(Math.abs(totalMinutes) % 60).padStart(2, '0');
      const sign = totalMinutes < 0 ? '-' : '';
      return `${sign}${hours}:${minutes}`;
    },
    calculateDuration(start, end) {
      if (!end) {
        return '--:--';
      }
      const startTime = moment(start);
      const endTime = moment(end);

      let duration = moment.duration(endTime.diff(startTime));

      if (duration.asSeconds() > 0 && duration.asSeconds() < 60) {
        duration = moment.duration(1, 'minutes');
      }

      const totalMinutes = duration.asMinutes();
      const isNegative = totalMinutes < 0;
      const absMinutes = Math.abs(totalMinutes);
      const hours = String(Math.floor(absMinutes / 60)).padStart(2, '0');
      const minutes = String(Math.floor(absMinutes % 60)).padStart(2, '0');
      const sign = isNegative ? '-' : '';

      return `${sign}${hours}:${minutes}`;
    },
    getRemainingTime(estimation, workLogs) {
      if(estimation && workLogs) {
        const totalTime = this.getTotalTimeInMinutes(workLogs);
        const [estHours, estMinutes] = estimation.split(':').map(Number);
        const estTotalMinutes = estHours * 60 + estMinutes;
        const remainingMinutes = estTotalMinutes - totalTime;

        const sign = remainingMinutes < 0 ? '-' : '';
        const absRemainingMinutes = Math.abs(remainingMinutes);
        const hours = Math.floor(absRemainingMinutes / 60).toString().padStart(2, '0');
        const minutes = (absRemainingMinutes % 60).toString().padStart(2, '0');
        return `${sign}${hours}:${minutes}`;
      }
    },
    getTotalTimeInMinutes(workLogs) {
      return workLogs.reduce((total, log) => {
        if (!log.end) {
          return total;
        }
        const startTime = moment(log.start);
        const endTime = moment(log.end);
        let duration = moment.duration(endTime.diff(startTime));
        if (duration.asSeconds() > 0 && duration.asSeconds() < 60) {
          duration = moment.duration(1, 'minutes');
        } else {
          duration = moment.duration(Math.ceil(duration.asMinutes()), 'minutes');
        }
        return total + duration.asMinutes();
      }, 0);
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    openHistoryDialog() {
      this.isHistoryDialogOpen = true;
    },
    closeHistoryDialog() {
      this.isHistoryDialogOpen = false;
    },
    restoreTask(uuid) {
      api
        .restoreTask(uuid)
        .then(() => {
          this.$emit("restoreTask");
          this.snack({
            text: `Zadanie "${this.task.topic}" zostało przywrócone`,
            severity: "info",
          });
        })
        .catch((error) => {
          console.error(error);
          this.snack({
            text: `Wystąpił błąd" ${error.response.data.message}`,
            severity: "error",
          });
        })
        .finally(() => {
          this.$emit("close");
        });
    },
    pushIdToUrl(uuid) {
      if (this.task || this.t) {
        const defaultUrl = window.location.href.split("?").shift();
        window.location.replace(defaultUrl);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("taskId", uuid);
        const newUrl = `${defaultUrl}?${urlParams.toString()}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
      }
    },
    isDisabledEstimation() {
      const isAdmin = !!this.$store.getters.isAdmin;
      return (!isAdmin) && (!!this.$store.getters.isUser ||
        (!!this.t.estimation && !!this.t.id && !!this.$store.getters.isAccount))
    },
    displayCreatedAt(createdAt) {
      const locale = new Date(createdAt).toLocaleString();
      return locale.replace(",", "").slice(0, locale.lastIndexOf(":") - 1);
    },
    downloadFile() {
      if (this.displayedFiles.length === 0) {
        this.snack({
          text: "Aktulany folder nie zawiera plików",
          severity: "warning",
          side: "left",
        });
      } else {
        const data = [
          {
            table1: this.displayedFiles
              .filter((file) => !file.isdir)
              .map((file) => {
                return { name: file.name };
              }),
          },
        ];
        const SETTINGS_FOR_EXPORT = {
          fileName: this.generateTaskName,
          workSheets: [
            {
              sheetName: "Pliki",
              startingRowNumber: 2,
              tableSettings: {
                table1: {
                  tableTitle: "Aktualny folder" + this.displayedFiles[0].path,
                  headerDefinition: [
                    {
                      name: "Nazwa pliku",
                      key: "name",
                    },
                  ],
                },
              },
            },
          ],
        };
        const excelExport = new ExcelExport();
        excelExport.downloadExcel(SETTINGS_FOR_EXPORT, data);
      }
    },
    refreshFiles(task) {
      // Fetch files not required for files without saved folder path or in 'done' status
      if (!task.folder_path || task.folder_path === "") {
        return true;
      }
      if (task.status !== "done") {
        return api
          .getFiles(task.uuid)
          .then((response) => {
            this.rootFiles = response.data;
            this.displayedFiles = this.showDirsFirst(
              response.data[this.filesType].files
            );
            this.rootPath = this.currentPath =
              response.data[this.filesType].rootPath;
            return true;
          })
          .catch((error) => {
            console.log(error);
            this.snack({
              text: "Wystąpił błąd przy pobieraniu listy plików",
              severity: "error",
            });
            return false;
          })
          .finally(() => {
            this.loadingFiles = false;
          });
      } else {
        return api
          .getArchivedFiles(task.uuid)
          .then((response) => {
            this.rootFiles = response.data;
            this.displayedFiles = this.showDirsFirst(
              response.data[this.filesType].files
            );
            this.rootPath = this.currentPath =
              response.data[this.filesType].rootPath;
            return true;
          })
          .catch((error) => {
            console.log(error);
            this.snack({
              text: "Wystąpił błąd przy pobieraniu listy plików",
              severity: "error",
            });
            return false;
          })
          .finally(() => {
            this.loadingFiles = false;
          });
      }
    },
    refreshTask(uuid) {
      return api
        .getTask(uuid)
        .then((response) => {
          const deadline = response.data.deadline
            ? response.data.deadline.replace(" ", "T")
            : "";
          this.t = { ...response.data, deadline };
          this.$store.commit("TASK_UPDATED", response.data);
          this.pushIdToUrl(uuid);
          return true;
        })
        .catch((error) => {
          console.log(error);
          this.snack({
            text: "Wystąpił błąd przy odczycie zadania",
            severity: "error",
          });
          return false;
        })
        .finally(() => {
          this.refreshing = false;
        });
    },
    async refresh(task) {
      this.refreshing = true;
      this.loadingFiles = true;
      const refreshed = await this.refreshTask(task.uuid);
      const updated = await this.refreshFiles(task);
      if (refreshed && updated) {
        this.snack({
          text: "Zadanie zostało odświeżone",
          severity: "info",
        });
      }
    },
    copyToClipboard() {
      const copyName = this.generateTaskName;
      navigator.clipboard.writeText(copyName);
      this.snack({
        text: "Skopiowano do schowka",
        severity: "info",
        side: "right",
      });
    },
    showDirsFirst(array) {
      if (!array || !Array.isArray(array)) {
        return array;
      }
      return array.sort((a, b) => {
        if (a.isdir && b.isdir) return 0;
        if (a.isdir && !b.isdir) return -1;
        else return 1;
      });
    },
    toLocaleDateTimeString(time) {
      return (
        new Date(time * 1000).toLocaleDateString() +
        " " +
        new Date(time * 1000).toLocaleTimeString()
      );
    },
    copyPathToClipboardMac(path) {
      const copyPath = "/Volumes" + path;
      navigator.clipboard.writeText(copyPath);
      this.snack({
        text: "Skopiowano do schowka",
        severity: "info",
        side: "right",
      });
    },
    copyPathToClipboardWindows(path) {
      const copyPath =
        "\\\\" +
        process.env.VUE_APP_SYNOLOGY_DOMAIN +
        path.replaceAll("/", "\\");
      navigator.clipboard.writeText(copyPath);
      this.snack({
        text: "Skopiowano do schowka",
        severity: "info",
        side: "right",
      });
    },
    canViewHistory() {
      return (
        this.$store.getters.isAdmin ||
        this.$store.state.user.id === this.t.employee_id ||
        this.$store.state.user.id === this.t.account_id
      );
    },
    goBack(path) {
      let index = path.lastIndexOf("\\");
      if (index === -1) index = path.lastIndexOf("/");
      this.openSubFolder(path.substring(0, index), null);
    },
    openSubFolder(path, index) {
      this.loadingSubfolder = { active: true, index };
      api
        .getFilesByPath(path)
        .then((response) => {
          this.displayedFiles = this.showDirsFirst(response.data.files);
          this.currentPath = response.data.rootPath;
        })
        .catch((error) => console.log(error))
        .finally(
          () => (this.loadingSubfolder = { active: false, index: null })
        );
    },
    getShareLink(path, index = -1) {
      this.gettingShareLink = { active: true, index };
      api
        .getShareLink(path)
        .then((response) => {
          navigator.clipboard.writeText(response.data.path);
          this.snack({
            text: "Skopiowano do schowka",
            severity: "info",
            side: "right",
          });
        })
        .catch((error) => console.log(error))
        .finally(
          () => (this.gettingShareLink = { active: false, index: null })
        );
    },
    isChecked(value) {
      return this.filesType.includes(value);
    },
    ...mapActions({
      snack: "snack/snack",
      side: "snack/side",
    }),
    validateTopic(value) {
      return this.errors.topic && !value ? "is-invalid" : "";
    },
    validateCorporateClient(value) {
      return this.errors.corporate_client_id && !value ? "is-invalid" : "";
    },
    validateDescription(value) {
      return this.errors.description && !value ? "is-invalid" : "";
    },
    validateDeadline(value) {
      return (this.errors.deadline && !value) ||
        (this.errors.deadline && this.lateness)
        ? "is-invalid"
        : "";
    },
    validateEstimation(value) {
      return (this.errors.deadline && !value) ||
      (this.errors.estimation && !this.validTime(value))
        ? "is-invalid"
        : "";
    },
    validTime(value) {
      if(value) {
        const isValidFormat = /^\d{2}:\d{2}$/.test(value);
        const [hours, minutes] = value.split(':').map(Number);
        return isValidFormat && (hours !== 0 || minutes !== 0)
      } else {
        return false
      }
    },
    filesTabHandler(event) {
      this.filesType = event.target.value;
      if (this.rootFiles) {
        const toBeDisplayed = this.rootFiles[event.target.value].files;
        this.displayedFiles = toBeDisplayed
          ? this.showDirsFirst(toBeDisplayed)
          : [];
        this.rootPath = this.currentPath = this.rootFiles[
          event.target.value
        ].rootPath;
      }
    },
    close() {
      this.$emit("close");
      this.t = {
        topic: null,
        employee_id: this.employee_id,
        account_id: this.$store.getters.isAccount
          ? this.$store.state.user.id
          : null,
        corporate_client_id: null,
        deadline: null,
        status: null,
      };
      this.media = "materials";
    },
    handleSave() {
      if (this.loading) {
        return;
      }
      if (this.task || this.t.id) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    handleUpdate() {
      if (
        !this.task ||
        this.task.topic !== this.t.topic ||
        this.task.status !== this.t.status ||
        this.task.description !== this.t.description ||
        this.task.corporate_client_id !== this.t.corporate_client_id ||
        this.task.account_id !== this.t.account_id ||
        this.task.deadline !== this.t.deadline ||
        this.task.employee_id !== this.t.employee_id ||
        this.task.estimation !== this.t.estimation
      ) {
        if ((this.t.status === "" || this.t.status === null) && this.lateness) {
          this.errors = {
            deadline: true,
          };
          this.snack({
            text: "Proszę zaktualizować polę Deadline",
            severity: "error",
          });
          return;
        }
        if (
          !this.t.topic ||
          !this.t.corporate_client_id ||
          !this.t.description ||
          !this.t.deadline ||
          !this.t.estimation
        ) {
          this.errors = {
            topic: !this.t.topic,
            corporate_client_id: !this.t.corporate_client_id,
            description: !this.t.description,
            deadline: !this.t.deadline,
            estimation: !this.t.estimation
          };
          this.snack({
            text:
              "Pola temat, klient, opis zadania, deadline i estymacja muszą być wypełnione",
            severity: "error",
          });
          return;
        }
        this.loading = true;
        api
          .updateTask(this.t.uuid, this.t)
          .then((response) => {
            this.snack({
              text: `Zadanie zostało zaktualizowane: ${response.data.topic}`,
              severity: "info",
            });
            this.$store.commit("TASK_UPDATED", response.data);
            this.t = response.data;
            this.refreshFiles(response.data);
          })
          .catch((error) => {
            console.error(error);
            this.snack({
              text: `Wystąpił błąd przy zapisywaniu zadania: ${error.response.data.message}`,
              severity: "error",
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit("close");
          });
      }
    },
    handleCreate() {
      if (
        !this.t.topic ||
        !this.t.corporate_client_id ||
        !this.t.description ||
        !this.t.deadline ||
        !this.t.estimation
      ) {
        this.errors = {
          topic: !this.t.topic,
          corporate_client_id: !this.t.corporate_client_id,
          description: !this.t.description,
          deadline: !this.t.deadline,
          estimation: !this.t.estimation
        };
        this.snack({
          text:
            "Pola temat, klient, opis zadania, deadline i estymacja muszą być wypełnione",
          severity: "error",
        });
        return;
      }
      if (this.lateness) {
        this.errors = {
          deadline: true,
        };
        this.snack({
          text: "Deadline nie może być w przeszłości",
          severity: "error",
        });
        return;
      }
      if (this.t.status === "-") this.t.status = null;
      this.loading = true;
      api
        .createTask(this.t)
        .then((response) => {
          this.snack({
            text: `Utworzono nowe zadanie: ${response.data.topic}`,
            severity: "info",
          });
          this.$store.commit("ADD_TASK", response.data);
          this.t = response.data;
          this.refreshFiles(this.t);
          this.visable = true;
          this.pushIdToUrl(this.t.uuid);
        })
        .catch((error) => {
          console.error(error);
          this.snack({
            text: `Wystąpił błąd przy zapisywaniu zadania: ${error.response.data.message}`,
            severity: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.isOpen = false;
        });
    },
    charCount: function(string) {
      return string ? string.length : 0;
    },
    sendNotification() {
      this.sending = true;
      api
        .sendNotification(this.t.uuid)
        .then((response) => {
          this.snack({
            text: `${response.data.msg}`,
            severity: "info",
          });
        })
        .catch((error) => {
          console.error(error);
          this.snack({
            text: `Wystąpił błąd podczas wysyłania powiadomienia: ${error.response.data.message}`,
            severity: "error",
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    saveLog(log) {
      const startTime = moment(log.start);
      const endTime = log.end ? moment(log.end) : null;

      if (endTime && !endTime.isAfter(startTime)) {
        this.snack({
          text: `Czas końcowy musi być później niż czas początkowy`,
          severity: "error",
        });
        return;
      }

      const payload = {
        start: startTime.format('YYYY-MM-DD HH:mm:ss'),
        end: endTime ? endTime.format('YYYY-MM-DD HH:mm:ss') : null,
      };

      api
        .updateWorkLog(log.id, payload)
        .then((response) => {
          this.$store.commit("TASK_UPDATED", response.data);
          this.snack({
            text: `Zaktualizowano czas`,
            severity: "info",
          });
          log.isEditing = false;
        })
        .catch((error) => {
          console.error(error);
          this.snack({
            text: `Wystąpił błąd podczas aktualizacji czasu: ${error.response.data.message}`,
            severity: "error",
          });
        })
    }
  },
  computed: {
    minDate() {
      let date = new Date();
      if (date.getHours() >= 14) {
        date.setDate(date.getDate() + 1);
      }
      return date.toISOString().slice(0, 16);
    },
    lateness: function() {
      if (this.t.deadline) return new Date(this.t.deadline) - Date.now() < 0;
      else return false;
    },
    filteredFiles() {
      return this.displayedFiles
        .slice(0)
        .filter(
          (file) => !file.name.startsWith("~") && file.name !== "Thumbs.db"
        )
        .sort((a, b) => {
          if (!a.isdir && !b.isdir) {
            return a.additional.time.mtime - b.additional.time.mtime;
          } else {
            return 0;
          }
        });
    },
    getSelectedClient() {
      if (this.t.corporate_client_id) {
        const client = this.$store.state.corporateClients.find(
          (el) => el.id === this.t.corporate_client_id
        );
        return client ? client.name : "";
      } else {
        return "";
      }
    },
    generateTaskName() {
      if (this.rootPath !== "") {
        const sections = this.rootPath.split("/");
        return sections[2] + "_" + sections[3];
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
$color-blue: #1a36ad;

.topic-count {
  position: absolute;
  right: 15px;
  top: 39px;
  color: #bbbbbb;
}
.topic-info {
  position: absolute;
  right: 12px;
  top: 64px;
  color: #bbbbbb;
}
.background {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 5000;
}
.dialog-background {
  background-color: #21252944;
  display: block;
}

.dialog {
  max-width: 60%;
}

.modal-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}

.data-margin {
  margin: 2rem 0;
}

label {
  width: 100%;
  margin-top: 1rem;
  font-weight: 700;
}

th {
  font-weight: 400;
  padding: 0.7rem;
}

td {
  padding: 0 0 0 0.7rem;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

.files__box {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 5px;

  .file__option {
    text-transform: uppercase;
    border: 1px solid black;

    label:hover {
      cursor: pointer;
    }

    & input[type="radio"] {
      visibility: hidden;
      position: fixed;
      height: 0;
      width: 0;
    }

    &.select {
      border: 1px solid $color-blue;
      background-color: $color-blue;
      color: white;
    }

    & label {
      margin: 2px 0 0 0;
      padding: 5px 20px;
    }
  }
}

.files-table {
  max-width: 100%;

  td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-name {
    width: 40%;
  }
}
.file-info {
  margin: 20px 0;
}
.toolbar {
  display: flex;
  border: solid 1px;
  justify-content: space-between;
  padding: 0 2rem;
  margin: 10px 0;
}
.toolbar-button {
  padding: 0.5rem;
  border: none;
  background-color: #fff;
}
.toolbar-button:hover {
  background-color: #1a36ad;
  color: #fff;
}
.toolbar-button:active {
  background-color: #6886ef;
}
.btn-center {
  display: flex;
  margin: 10px auto;
}
.extra-modal {
  background-color: #fff;
}
.close {
  background-color: #6886ef;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: all .4s ease;
  &:hover {
    background-color: #1a36ad;
  }
}
.history__time {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;

  & p {
    margin-bottom: 0;
  }
}
.estimation-history {
  border-top: 2px solid #1a36ad;
  margin-top: 20px;
}
.modal-dialog {
  max-width: 65%;
}
.btn-close {
  margin-left: 6px;
  cursor: pointer;
  font-size: 16px;
}
</style>
