<template>
  <div class="user_status">
    <p v-if="userStatus" :class="userStatus.color">{{userStatus.name}}</p>
  </div>
</template>

<script>
import {userStatus}  from "@/mixins";

export default {
  name: "UserStatus",
  mixins:[userStatus],
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.user_status {
  .success:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $success;
    display: inline-block;
    margin-right: 8px;
  }
  .warning:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $warning;
    display: inline-block;
    margin-right: 8px;
  }
  .danger:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $danger;
    display: inline-block;
    margin-right: 8px;
  }

}

</style>