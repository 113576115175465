import api from "@/api";

export const userStatus = {
    props:{
        status: String,
    },
    data() {
        return {
            statuses: [
                {
                    status: 'normal',
                    name: 'Pracuje normalnie',
                    color: 'success'
                },
                {
                    status: 'l4',
                    name: 'L4',
                    color: 'danger'
                },
                {
                    status: 'vacation',
                    name: 'Urlop',
                    color: 'danger'
                },
                {
                    status: 'homeoffice',
                    name: 'Homeoffice',
                    color: 'warning'
                },
                {
                    status: 'shortened_workday',
                    name: 'Skrócony dzień pracy',
                    color: 'warning'
                },
            ],
            selectedStatus: this.status,
        }
    },
    methods: {
        updateUserStatus(id, status) {
            api.updateUserStatus(id, status)
                .then()
                .catch(err => {
                    console.error(err)
                    this.selectedStatus = this.status
                })
        }
    },
    computed: {
        userStatus: function () {
            return this.statuses.find(x => x.status === this.selectedStatus)
        }
    },
}