<template>
  <router-view />
  <snackbar/>
</template>

<script>
import api from './api';
import {mapActions, mapState} from 'vuex';
import Snackbar from "@/components/Snackbar";

export default {
  components:{
    Snackbar
  },
  computed: {
    ...mapState(['user', 'users', 'corporateClients'])
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.fetchData();
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('auth_token')) {
      api.getLoggedInUser()
        .then(response => {
          this.$store.dispatch('setUser', response.data);
          const queryParams = this.$route.query.taskId ? '?taskId=' + this.$route.query.taskId : '';
          this.$router.push('/home' + queryParams);
        })
        .catch(error => {
          if (error.response && error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
            this.$store.dispatch('logout');
            this.snack({text: "Wygasł token, zaloguj się ponownie", severity: "info"});
            setTimeout(() => {
              this.$router.go('/login');
            }, 2000);
          } else {
            console.error('Error fetching logged in user:', error);
          }
        })
    }
  },
  methods: {
    ...mapActions({
      setUser: 'setUser',
      clearUser: 'clearUser',
      fetchUsers: 'fetchUsers',
      fetchCorporateClients: 'fetchCorporateClients',
      snack: 'snack/snack'
    }),
    fetchData() {
      if (this.users.length === 0) {
        this.fetchUsers();
      }
      if (this.corporateClients.length === 0) {
        this.fetchCorporateClients();
      }
    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "assets/scss/variables";
@import "~bootstrap";
@import "assets/icomoon/style.css";
.popover-body {
  img{max-width: 100% !important;}
}
html {
  font-size: 13px;
  color: $black;
}
h1 {
  font-size: 29px;
  font-weight: 600;
  letter-spacing: .2px;
  line-height: 47px;
}
td{ vertical-align: middle;}
.cursor-pointer{
  cursor: pointer;
}
.table-striped > tbody > tr:nth-of-type(odd),
.table-striped > tbody > tr:nth-of-type(even){
  &.disabled_row {
    td, i {
      color: #C8C9CC !important;
    }
  }
}

</style>
