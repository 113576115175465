<template>
  <app-wrapper>
    <page-header title="Lista klientów"></page-header>

    <div class="container my-5">
      <div class="row">
        <div class="col">
          <input class="form-control" placeholder="id klienta" v-model="key" />
        </div>
        <div class="col">
          <input
            class="form-control"
            placeholder="nazwa klienta"
            v-model="name"
          />
        </div>
        <div class="col col-auto">
          <button class="btn btn-primary" @click="handleCorporateClientCreate">
            Dodaj
          </button>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <table class="table table-striped gs-4 gy-4">
            <thead>
              <tr>
                <th @click="sort('key')" class="sortable">id</th>
                <th @click="sort('name')" class="sortable">nazwa</th>
                <th>data utworzenia</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody v-if="corporateClients.length > 0">
              <tr v-for="(c, index) in corporateClients" :key="c.id">
                <td>{{ c.key ? c.key : "Brak" }}</td>
                <td v-if="index === edit">
                  <input
                    class="form-control"
                    placeholder="nazwa klienta"
                    v-model="editedClient.name"
                  />
                </td>
                <td v-else>{{ c.name }}</td>
                <td>{{ getDateTime(c.created_at) }}</td>
                <td>
                  <i
                    v-if="index === edit"
                    class="icon-Check cursor-pointer"
                    @click="handleCorporateClientEdit(c)"
                  />
                  <i
                    v-else
                    class="icon-Pencil cursor-pointer"
                    @click="enableEdit(index, c)"
                  />
                </td>
                <td>
                  <i
                    v-if="index === edit"
                    class="icon-Undo cursor-pointer"
                    @click="closeEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import AppWrapper from "@/components/AppWrapper";
import PageHeader from "@/components/PageHeader";
import api from "../api";
import { mapActions } from "vuex";
export default {
  name: "CorporateClients",
  components: { PageHeader, AppWrapper },
  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      name: null,
      id: null,
      edit: -1,
      editedClient: null,
    };
  },
  computed: {
    corporateClients() {
      return this.$store.state.corporateClients.slice(0).sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (
          a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()
        )
          return -1 * modifier;
        if (
          a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()
        )
          return 1 * modifier;
        return 0;
      });
    },
  },
  methods: {
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    ...mapActions({
      snack: "snack/snack",
    }),
    enableEdit(index, client) {
      this.edit = index;
      this.editedClient = { ...client };
    },
    closeEdit() {
      this.edit = -1;
      this.editedClient = null;
    },
    getDateTime(dateTime) {
      const date = new Date(dateTime);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    handleCorporateClientEdit() {
      if (
        this.validateClientName(
          this.editedClient.name.trim(),
          this.editedClient.id
        )
      ) {
        api
          .updateCorporateClient(
            this.editedClient.id,
            this.editedClient.name.trim(),
            this.editedClient.key
          )
          .then((response) => {
            this.edit = -1;
            this.snack({
              text: `Nazwa klienta została zmieniona`,
              severity: "info",
            });
            this.$store.commit("UPDATE_CORPORATE_CLIENT", response.data);
          })
          .catch((error) => {
            console.error(error);
            if (error.response.data.errors) {
              this.snack({
                text: error.response.data.errors.name[0],
                severity: "error",
              });
            } else {
              this.snack({ text: `Błąd serwera`, severity: "error" });
            }
          });
      }
    },
    handleCorporateClientCreate() {
      if (this.validateNewClient()) {
        api
          .createCorporateClient(this.name.trim(), this.key.trim())
          .then((response) => {
            this.name = null;
            this.key = null;
            this.snack({ text: `Dodano nowego klienta`, severity: "info" });
            this.$store.commit("ADD_CORPORATE_CLIENT", response.data);
          })
          .catch((error) => {
            console.error(error);
            if (error.response.data.errors) {
              this.snack({
                text: error.response.data.errors.name[0],
                severity: "error",
              });
            } else {
              this.snack({ text: `Błąd serwera`, severity: "error" });
            }
          });
      }
    },
    validateClientName(name, id = null) {
      if (!name || name.trim() === "") {
        this.snack({
          text: `Nazwa klienta jest wymagana`,
          severity: "warning",
        });
        return false;
      }
      if (
        name &&
        this.corporateClients.find(
          (c) =>
            c.name.toLowerCase() === name.toLowerCase().trim() && c.id !== id
        )
      ) {
        console.log(
          this.corporateClients.find(
            (c) =>
              c.name.toLowerCase() === name.toLowerCase().trim() && c.id !== id
          )
        );
        this.snack({
          text: `Klient o podanej nazwie już istnieje`,
          severity: "warning",
        });
        return false;
      }
      return true;
    },
    validateNewClient() {
      const regex = new RegExp("^[A-Za-z0-9-]*$");
      if (!this.key || this.key.trim() === "") {
        this.snack({ text: `Id klienta jest wymagane`, severity: "warning" });
        return false;
      }
      if (
        this.key &&
        this.corporateClients.find(
          (c) => c.key.toUpperCase() === this.key.toUpperCase().trim()
        )
      ) {
        this.snack({
          text: `Klient o podanym id już istnieje`,
          severity: "warning",
        });
        return false;
      }
      if (this.key && !regex.test(this.key.toUpperCase().trim())) {
        this.snack({
          text: `Id klienta musi zawierać jedynie  cyfry, litery lub znak '-'`,
          severity: "warning",
        });
        return false;
      }
      return this.validateClientName(this.name);
    },
  },
};
</script>

<style scoped>
th.sortable {
  cursor: pointer;
}
</style>
