<template>
  <div v-if="$store.state.user" class="row">
    <div class="col">
      <quick-filters title="Pracownicy" :options="$store.getters.userNames" v-model="$store.state.filters.users"/>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col">
      <quick-filters title="Grupy" :options="$store.getters.groupNames" v-model="$store.state.filters.groups"
                     :options-labels="{graphic_creative: 'grafik kreatywny', graphic_dtp: 'grafik dtp', graphic_ui: 'grafik UI (aplikacje i www)', ux: 'UX', it: 'IT', dev_frontend: 'programista frontend', dev_backend: 'programista backend'}"/>
    </div> -->
  </div>
  <div v-if="$store.getters.isAdmin || $store.getters.isAccount" class="row">
    <div class="col">
      <quick-filters title="Akanci" :options="$store.getters.accountNames" v-model="$store.state.filters.accounts"/>
    </div>
  </div>
  <div v-if="$store.getters.isAdmin || $store.getters.isAccount" class="row">
    <div class="col">
      <div class="quick_filter">
        <h4 class="my-2">Magiczne filtry</h4>
        <div class="filter_tag"
             @click="$store.state.filters.unapproved = !$store.state.filters.unapproved"
             :class="{selected: $store.state.filters.unapproved}">
          niezaakceptowane ({{ unapprovedCount }})
        </div>
        <div class="filter_tag"
             @click="$store.state.filters.unassigned = !$store.state.filters.unassigned"
             :class="{selected: $store.state.filters.unassigned}">
          zadania nieprzypisane ({{ unassignedCount }})
        </div>
        <div class="filter_tag"
             @click="$store.state.filters.excludeEmpty = !$store.state.filters.excludeEmpty"
             :class="{selected: $store.state.filters.excludeEmpty}">
          ukryj puste
        </div>
      </div>
    </div>
  </div>
  <div v-if="$store.state.user" class="row">
    <div class="col-2 quick_filter">
      <h4 class="my-2">Klienci</h4>
      <select class="form-select" v-model="$store.state.filters.clientId">
        <option
            :value="null"
        >
          Wszyscy
        </option>
        <option
            v-for="client in $store.getters.sortedClients"
            :key="client.id"
            :value="client.id"
        >
          {{ client.name }}
        </option>
      </select
      >
    </div>
  </div>
</template>

<script>
import QuickFilters from "@/components/QuickFilters";

const substract = (a, b) => a.filter(x => !b.includes(x))

export default {
  name: "TaskFilters",
  components: {QuickFilters},
  computed: {
    groups() {
      return this.$store.state.filters.groups
    },
    users() {
      return this.$store.state.filters.users
    },
    unapprovedCount() {
      return this.$store.state.tasks.filter(t => t.unapproved === 1).length
    },
    unassignedCount() {
      return this.$store.state.tasks.filter(t => t.employee_id === 3).length
    },
  },
  watch: {
    groups(nv, ov) {
      if (nv.length > ov.length) {
        this.$store.commit('ADD_USERS_TO_FILTER_FROM_GROUP', substract(nv, ov)[0])
      } else {
        this.$store.commit('REMOVE_USERS_FROM_FILTER_FROM_GROUP', substract(ov, nv)[0])
      }
    }
  },
}
</script>

<style scoped lang="scss">
.filter_tag {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: $primary;
  color: white;
  display: inline-flex;
  margin-right: 4px;
  margin-bottom: 4px;

  &.selected {
    background-color: $secondary;
  }
}
</style>