<template>
  <div v-if="$store.state.loading" class="overlay">
    <div>
      <div
        class="spinner-border text-light"
        style="width: 5rem; height: 5rem"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row app_container">
    <aside
      class="d-flex flex-column flex-shrink-0 me-5 text-white h-100"
      style="width: 280px; z-index: 1060;"
    >
      <div class="position-fixed bg-primary h-100" style="width: 280px;">
        <div class="p-4 d-flex flex-column">
          <div class="d-flex justify-content-between flex-row flex-nowrap">
            <img src="../assets/media/logo_white.svg" />
            <div
                class="zip"
                ref="zip"
                @click="changeZipContent"
                data-bs-toggle="popover"
                data-bs-placement="top"
                :data-bs-content="zipcontent"
            >
              <mr-zip style="transform: rotateY(180deg)" />
            </div>
          </div>
          <small class="mt-5" v-if="user">Zalogowany jako</small>
          <h3 class="mt-1" v-if="user">{{ user.name }}</h3>
<!--          <h3 class="mt-4 blink" v-else>ZALOGUJ SIĘ</h3>-->
          <small v-if="user && user.type === 'admin'" class="mt-1"
            >ADMINISTRATOR</small
          >
          <small v-if="user && user.groups" class="mt-1"
            ><span
              class="group_name"
              v-for="(ug, index) in user.groups"
              :key="'ug_' + index"
              >{{ ug.name }}</span
            ></small
          >
          <hr />
        </div>

        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <router-link class="nav-link text-white" to="/home"
              ><i class="icon-Table" />Tabelka</router-link
            >
          </li>
          <li
            v-if="$store.state.user && $store.state.user.type === 'admin'"
            class="nav-item"
          >
            <router-link class="nav-link text-white" to="/users"
              ><i class="icon-Person" />Pracownicy</router-link
            >
          </li>
          <li
            v-if="$store.state.user && $store.state.user.type === 'admin'"
            class="nav-item"
          >
            <router-link class="nav-link text-white" to="/corporate-clients"
              ><i class="icon-Office" />Klienci</router-link
            >
          </li>
          <li
            v-if="$store.state.user && $store.state.user.type === 'admin'"
            class="nav-item"
          >
            <router-link class="nav-link text-white" to="/reports"
              ><i class="icon-Calendar" />Raporty</router-link
            >
          </li>
          <li v-if="$store.state.user" class="nav-item">
            <router-link class="nav-link text-white" to="/archive"
              ><i class="icon-Archive" />Archiwum</router-link
            >
          </li>
          <li class="nav-item">
            <button class="btn btn-primary mt-3 mb-3 w-100" @click="logout">
              Wyloguj się
            </button>
          </li>
        </ul>
      </div>
    </aside>
    <main class="d-flex flex-column flex-fill">
      <slot></slot>

      <div class="container">
        <div class="row mt-5 mb-5">
          <div class="col col-lg-6 offset-lg-6 text-end">
            <hr />
            <h6>By Expansja Advertising</h6>
            <small style="color: #bbbbbb;">
              Tabelka odpowiada żywotnym potrzebom całej Expansji. To jest
              oprogramowanie na miarę naszych możliwości. Otwieramy oczy
              niedowiadkom. To jest nasze, przez nas wykonane, i to nie jest
              nasze ostatnie słowo! I nikt nie ma prawa się przyczepić!<br /><br />
              Serdeczne podziękowania: <br />
              za przygotowanie od strony wizualnej -
              <strong
                >Wojtek K, Maciej K, Marcel B ("przycisk"), Karolina S</strong
              ><br />
              za Pana Spinacza - <strong>Hania K, Paweł K</strong><br />
              za "kiedy będzie moja tabelka!?" i cierplowość do wielokrotnego
              "pracujemy nad tym, do końca tygodnia zrobimy" -
              <strong>Tomasz T</strong><br />
            </small>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from '../api';
import { mapState } from "vuex";
import MrZip from "@/components/MrZip";
const bootstrap = require("bootstrap");

const zipPickUpLines = [
  "Idż z tym do Tomka",
  "U mnie działa",
  "Dobra, to dam to komuś innemu, zrobimy szybciej",
  "Dobra, nie tłumacz mi tego, bo i tak nie zrozumiem",
  "Trzeba mieć momenty",
  "Im więcej szczegółów przekażesz tym mniej będziesz kłócić się z Michałem!",
  "Pamiętaj,że Marcel lubi dobre briefy w paint! Szanuj jego oczy!",
  "Czy poinformowałeś już Studio Dajrektora o Twoich niecnych zleceniach dla studia ?",
  "Pamiętaj! Nie od razu Westfield zbudowano,więc pewnych rzeczy na jutro zrobić się nie da :/",
  "Ulalala! A kto to jeszcze nie wpisał swoich prac do tabelki!? SHAME,SHAME,SHAME!",
  "Szanuj Grafika,DTPowca,Animatora i ITowca swojego, bo możesz mieć gorszego!",
  "No dobra, my tu gada-gadu,a może...zamówisz coś dziś z nami?",
  "Nawet tak na mnie nie patrz! To nie przyspieszy wykonania zlecenia?",
  "Kraina Lody 2 czy Król Lew 3D?",
  "Zapytaj Iwonkę czy ma coś słodkiego! :/",
  "Nim zrobisz gównoburzę o Twoje zlecenie upewnij się czy warto! Opisałeś dokładnie wszystko w zleceniu?",
  'Za każdym razem gdy krzyczysz "Michał,zjebałeś!" ginie na świecie jedna kuoka! Szanuj je :/',
  'Nie możesz się dogadać? Graficy to ludzie! Serio! A jak mówi stare porzekadło: :"Warto rozmawiać!" :D',
  "Legenda mówi,że gdy Wojtek dostał pełny brief to na drugi dzień świeciło słoneczko! Zrób wszystko,aby ono świeciło na drugi dzień!",
  'Trudne zlecenie? Klient jest zły? Pamiętaj! Masz być jak Tom Lee Jones w "Ściganym"! Jak wściekły byk! No i jazda!',
  "Bałagan w studio? Spokojnie, taki mamy klimat!",
  "Grosza daj Hovayowi! Przestrzenią potrząśnij...ułołoło!!!",
  "Hej! Jeśli miałbyś/miałabyś zostać atrybutem w Just Gym to jakim?",
  "Cześć Pszczółko!",
  "Wyglądasz jak Posek po całym dniu w pustej Posnani! Uśmiechnij się!",
  "Raz, dwa, trzy, Filip patrzy!",
  "Ile zostało Ci do weekendu?",
  "Nie męcz mnie, odpoczywam.",
  "Pytaj o co tylko zechcesz!",
  "Jak powiedział Budda – usiądź, przemyśl to, nie wstawaj. Zrobi się kiedyś samo.",
  "Ej, przecież jest dobrze!",
  "Jutro będzie gorzej.",
  "Foch? Napij się kawy.",
  "Kija rzeką nie nawrócisz. Czy jakoś tak.",
  "Dziś Ty zamawiasz obiad!",
  "Ślimak!!!! A nie, to ktoś inny drze r…..",
  "Owszem, chce Ci się.",
  "Nie marudź.",
  "Co Ci mam powiedzieć, jestem tylko spinaczem.",
  "Spinaj się!",
  "Uwaga, Szef!",
  "Właśnie odkrywasz sekret natury.",
  "Klient tak chce.",
  "Bez briefa nie podchodź.",
  "Pamiętaj o tabelce.",
  "Wiesz co…. Nie wiesz? Tak myślałem.",
  "Czekasz na jakiś głupi tekst? Kliknij jeszcze raz.",
  "Dobra, czas do domu.",
  "Spytaj Tomka.",
  "Idź do Filipa.",
  "He,he, mnie też Michał wkurzył.",
  "Weź głęboki oddech.",
  "Unexpected tekst spinacza.",
  null,
];

export default {
  name: "AppWrapper",
  components: { MrZip },
  computed: {
    ...mapState(["user"]),
  },
  data() {
    return {
      zipcontent: ";)",
    };
  },
  mounted() {
    if (this.$refs.zip) {
      new bootstrap.Popover(this.$refs.zip, {});
    }
  },
  methods: {
    changeZipContent() {
      this.zipcontent =
        zipPickUpLines[Math.floor(Math.random() * zipPickUpLines.length)];
    },
    async logout() {
      try {
        await api.logout();
        await this.$router.go('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
.blink {
  animation: blinker 1s linear infinite;
  color: transparent;
  text-align: center;
  margin-top: 200px;
}

@keyframes blinker {
  50% {
    color: red;
  }
}

.overlay {
  position: fixed;
  z-index: 6000;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.zip {
  & svg {
    height: 80px;
    width: 80px;
  }
}

.nav {
  .nav-link {
    padding: 24px;
    border-radius: 0;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: -0.1px;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      margin-left: 32px;
      margin-right: 16px;
    }
    &.router-link-active {
      background-color: $secondary;
    }
  }
}
.group_name {
  text-transform: uppercase;
  margin-right: 0.5rem;
}
img {
  max-width: 44px;
}
hr {
  color: #d3dde8;
}
</style>
