<template>
  <div class="my-5">
    <table class="table table-striped gy-4 gs-4 mt-5">
      <thead>
        <tr>
          <th style="width: 1%; white-space: nowrap"></th>
          <th style="width: 12%"><i class="icon-Office" />klient</th>
          <th style="width: 12%"><i class="icon-List1" />nr zadania</th>
          <th><i class="icon-Paragraph" />temat</th>
          <th style="width: 12%"><i class="icon-Person" />account</th>
          <th style="width: 12%"><i class="icon-Clock" />utworzono</th>
          <th style="width: 1%; white-space: nowrap"></th>
        </tr>
      </thead>
      <tbody>
        <archived-task
          v-for="t in tasks"
          :key="t.id"
          :task="t"
          :openTask="openTask(t)"
          @openDialog="handleClick(t)"
          @restoreTask="restoreTask"
        ></archived-task>
        <task-dialog
          v-if="openDialog"
          :task="selectedTask"
          :read-only="true"
          :is-dialog-open="openDialog"
          @close="handleClose"
          @restoreTask="restoreTask"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import ArchivedTask from "@/components/ArchivedTask";
import TaskDialog from "@/components/TaskDialog";

export default {
  name: "ArchivedTasks",
  data() {
    return {
      isTaskOpened: false,
      openDialog: false,
      selectedTask: null,
    };
  },
  components: {
    TaskDialog,
    ArchivedTask,
  },
  props: {
    tasks: Object,
    taskId: String,
    getArchivedTasks: {
      type: Function,
      required: true,
    },
    pageNumber: Number,
  },

  methods: {
    restoreTask() {
      this.getArchivedTasks(this.pageNumber);
    },
    openTask(task) {
      if (task.uuid === this.taskId && !this.isTaskOpened) {
        this.handleClick(task);
        this.isTaskOpened = true;
      }
    },
    handleClick(task) {
      this.selectedTask = task;
      this.openDialog = true;
    },
    handleClose() {
      this.openDialog = false;
      this.task = null;
    },
  },
};
</script>

<style scoped lang="scss">
thead {
  border-style: hidden;
  color: $primary;
  background: #dbe3fb;
  i {
    margin-right: 6px;
  }
}

th {
  padding-left: 20px;
  font-size: 11px;
  font-weight: 500;
}
</style>
