<template>
  <div class="quick_filter">
    <div>
      <h4 class="my-2">{{ title }}</h4>
    </div>
    <div v-if="options.length > 0">
      <div class="filter_tag"
           v-for="(o, index) in options"
           :key="index"
           @click="toggleOption(index)"
           :class="{selected: modelValue.includes(o)}">
        {{ optionsLabels && optionsLabels[o] ? optionsLabels[o] : o }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickFilters",
  props: {
    options: Array,
    title: String,
    modelValue: Array,
    optionsLabels: {
      type: Object,
      required: false,
    }
  },
  methods: {
    toggleOption(index) {
      let newModelValue = [...this.modelValue]
      if(this.modelValue.includes(this.options[index])) {
        newModelValue.splice(this.modelValue.indexOf(this.options[index]), 1)
      } else {
        newModelValue.push(this.options[index])
      }
      this.$emit('update:modelValue', newModelValue)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";
.quick_filter{

  .filter_tag {
    cursor: pointer;
    padding:4px 8px;
    border-radius: 3px;
    background-color: $primary;
    color: white;
    display: inline-flex;
    margin-right: 4px;
    margin-bottom: 4px;
    &.selected {
      background-color: $secondary;
    }
  }
}
</style>