<template>
  <div class="authorization_view">
    <div class="form-wrapper">
      <form class="form" @submit.prevent="resetPassword">
        <input type="hidden" v-model="token" required>
        <div>
          <label class="mb-2" for="email">Email:</label>
          <input class="form-control" type="email" v-model="email" required>
        </div>
        <div>
          <label class="mb-2" for="password">Nowe hasło:</label>
          <input class="form-control" type="password" v-model="password" required>
        </div>
        <div>
          <label class="mb-2" for="password_confirmation">Potwierdź nowe hasło:</label>
          <input class="form-control" type="password" v-model="password_confirmation" required>
        </div>
        <button class="btn btn-primary mt-4 mb-4" type="submit">Zresetuj hasło</button>
      </form>
    </div>
  </div>
</template>

<script>
import api from '../api';
import {mapActions} from "vuex";

export default {
  data() {
    return {
      token: '',
      email: '',
      password: '',
      password_confirmation: ''
    };
  },
  created() {
    this.token = decodeURIComponent(this.$route.params.token);
  },
  methods: {
    ...mapActions({
      snack: 'snack/snack'
    }),
    async resetPassword() {
      try {
        await api.resetPassword(this.token, this.email, this.password, this.password_confirmation);
        this.snack({text: "Zresetowano hasło", severity: "success"});
        this.$router.push('/login');
      } catch (error) {
        console.error('Error resetting password:', error);
        this.snack({text: "Nie udało się zresetować hasła.", severity: "error"});
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.authorization_view {
  height: 100vh;
  background-image: url("../assets/media/home_background.svg");
  background-color: $primary;
  color: $white;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 460px;
  width: 100%;
  margin-top: 140px;

  & label,
  & input {
    font-size: 16px;
  }
}
.btn {
  font-size: 16px;
  width: fit-content;
  align-self: center;
  text-transform: uppercase;
  background-color: #fff;
  color: $primary;
  font-weight: 600;

  &:hover {
    background-color: $gray-400;
  }

  & i {
    font-size: 12px;
    color: $primary;
  }
}
</style>
