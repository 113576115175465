<template>
  <div v-if="status" class="user_status_edit" :class="userStatus.color">
    <select v-model="selectedStatus" @change="updateUserStatus(user_id,$event.target.value)">
      <option v-for="(s, index) in statuses" :key="index" :value="s.status">{{s.name}}</option>
    </select>
  </div>
</template>

<script>
import {userStatus} from "@/mixins";

export default {
  name: "UserStatusEdit",
  mixins:[userStatus],
  props: {
    user_id: Number,
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.user_status_edit {
  select {
    border: none;
    background-color: transparent;
  }
  &.success:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $success;
    display: inline-block;
    margin-right: 8px;
  }
  &.warning:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $warning;
    display: inline-block;
    margin-right: 8px;
  }
  &.danger:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 3px;
    background-color: $danger;
    display: inline-block;
    margin-right: 8px;
  }

}

</style>