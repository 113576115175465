<template>
  <!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In  -->
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="110px"
       height="110px" viewBox="0 0 166.1 167.5" style="overflow:visible;enable-background:new 0 0 166.1 167.5;"
       xml:space="preserve">
    <defs>
</defs>
    <g>
	<g id="kartka_1_">
		<g>
			<path class="st0" d="M165.4,44.6c0,0-8.3,5.5-11.3,8.7c-6.4,6.8-9.8,14.6-13,23.2c-4.5,12.5-8.9,25.7-17.4,36.2
				c-20.9,25.6-36.9,27.9-36.9,27.9c-19,3.2-86.1-41.6-86.1-41.6c15.3-2.7,34.2-4.6,46.3-15.4c15.6-13.7,22.3-35.7,45.8-37.6
				C92.8,45.9,150.9,47.8,165.4,44.6z"/>
		</g>
    <path class="st1" d="M65.6,62.2c0,0,5.4,25.3,12.4,39.7c5.1,10.6,25.6,26.7,22.4,32.2c0,0-11.3,7.5-16.8,6.5c0,0-27.1-2-82.3-41.5
			c0,0,33.9-5.5,44.4-14.6C51.1,79.7,65.6,62.2,65.6,62.2z"/>
    <g>
			<g>
				<path class="st2" d="M165,44c-2.7,1.8-5.4,3.7-7.9,5.7c-4.2,3.3-7.4,7.4-10.1,12c-6.2,10.7-9,23-14.1,34.2
					c-2.5,5.6-5.5,11.1-9.4,15.9c-4,4.9-8.3,9.6-13,13.9c-5.6,5.1-11.9,9.8-19,12.6c-2.5,1-4.9,1.8-7.6,1.6
					c-5.6-0.2-11.1-2.4-16.2-4.4c-14.3-5.7-27.9-13.4-41.1-21.3c-5.6-3.3-11.2-6.8-16.7-10.3c-1.9-1.2-3.7-2.4-5.5-3.6
					c-1-0.6-1.9-1.4-3-1.9c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0.4-0.1,0.9-0.2,1.3c15.8-2.8,33.7-4.3,46.3-15.3
					c10.4-9.1,16.6-22,27.5-30.5c2.7-2.1,5.7-3.9,8.9-5.1c2.9-1.1,6.1-2,9.2-2c2.1,0,4.2,0.1,6.3,0.2c10.8,0.3,21.7,0.4,32.5,0.4
					c6,0,12-0.1,18-0.4c4.6-0.2,9.2-0.4,13.7-1.1c0.8-0.1,1.5-0.2,2.2-0.4c0.9-0.2,0.5-1.6-0.4-1.4c-4,0.9-8.1,1.1-12.2,1.3
					c-5.8,0.3-11.6,0.4-17.4,0.5c-11.3,0.1-22.6,0-33.9-0.3c-2.8-0.1-5.6-0.1-8.4-0.2c-1.7-0.1-3.4,0.3-5.1,0.6
					c-3.3,0.7-6.6,1.9-9.5,3.5C66.6,56,59.9,68.9,50.8,78.7c-2.6,2.8-5.4,5.4-8.6,7.5c-3.5,2.3-7.3,4.1-11.3,5.4
					c-8,2.8-16.4,4.2-24.7,5.5c-1.9,0.3-3.8,0.6-5.7,1c-0.6,0.1-0.6,1-0.2,1.3c7,4.7,14.1,9.1,21.3,13.4c13.4,8.1,27,15.9,41.3,22.2
					c5.6,2.4,11.5,4.8,17.5,5.9c3,0.5,5.9,0.6,8.7-0.3c5.7-1.8,10.9-5,15.6-8.5c4.2-3.1,8.1-6.7,11.8-10.4
					c4.5-4.6,8.8-9.6,12.3-15.1c6.6-10.7,9.9-22.7,14.6-34.3c2.3-5.8,5.2-11.4,9.1-16.2c3-3.6,6.6-6.3,10.4-9
					c0.9-0.6,1.8-1.2,2.7-1.8C166.5,44.7,165.8,43.5,165,44z"/>
			</g>
		</g>
	</g>
      <g id="spinacz_1_">
		<path id="ciałko_1_" class="st3" d="M80.7,59.5c0,0-2.1,2.8-2.5,6.9c0,0.3-0.2,31.5-0.2,31.5s0.4,11.8-11.9,11.5
			c-12.3-0.2-11.5-11-11.5-11l-0.2-75.2c0,0,0.1-7.3,8.4-7.3s9.1,6.8,9.1,6.8l0.2,56.9c0,0-0.1,6.4-5.4,6.4c-5.9,0-6.1-5.4-6.1-5.4
			c-0.3-3-0.5-22.4-0.5-22.4"/>
        <g id="oko_L_1_">
			<path class="st4" d="M61.1,40.3c0,4.7-3.8,8.6-8.6,8.6l0,0c-4.7,0-8.6-3.8-8.6-8.6l0,0c0-4.7,3.8-8.6,8.6-8.6l0,0
				C57.3,31.7,61.1,35.5,61.1,40.3L61.1,40.3z"/>
          <path d="M57.2,40.3c0,2.6-2.1,4.6-4.6,4.6l0,0c-2.6,0-4.6-2.1-4.6-4.6l0,0c0-2.6,2.1-4.6,4.6-4.6l0,0
				C55.1,35.6,57.2,37.7,57.2,40.3L57.2,40.3z"/>
          <path d="M60.1,36.4c0,0-3.3-3.2-7.7-3.3c-4.4-0.1-7.7,3.6-7.7,3.6s1.9-5.2,7.8-5C58.5,31.8,60.1,36.4,60.1,36.4z"/>
          <path d="M44.8,44c0,0,3.3,3.7,7.7,3.8c4.4,0.1,7.6-3.6,7.6-3.6s-1.7,5.1-7.7,4.9C46.4,49,44.8,44,44.8,44z"/>
          <path class="st4" d="M55.5,38.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
				C55,37.8,55.5,38.3,55.5,38.9z"/>
		</g>
        <g id="oko_P_1_">
			<path class="st4" d="M84,40.7c0,4.7-3.8,8.6-8.6,8.6l0,0c-4.7,0-8.6-3.8-8.6-8.6l0,0c0-4.7,3.8-8.6,8.6-8.6l0,0
				C80.1,32.1,84,35.9,84,40.7L84,40.7z"/>
          <path d="M80.1,40.7c0,2.6-2.1,4.6-4.6,4.6l0,0c-2.6,0-4.6-2.1-4.6-4.6l0,0c0-2.6,2.1-4.6,4.6-4.6l0,0C78,36,80.1,38.1,80.1,40.7
				L80.1,40.7z"/>
          <path d="M83,36.8c0,0-3.3-3.2-7.7-3.3c-4.4-0.1-7.7,3.6-7.7,3.6s1.9-5.2,7.8-5C81.4,32.3,83,36.8,83,36.8z"/>
          <path d="M67.7,44.4c0,0,3.3,3.7,7.7,3.8c4.4,0.1,7.6-3.6,7.6-3.6s-1.7,5.1-7.7,4.9C69.3,49.4,67.7,44.4,67.7,44.4z"/>
          <path class="st4" d="M78.4,39.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
				C77.9,38.2,78.4,38.7,78.4,39.3z"/>
		</g>
        <path id="brew_L_1_" d="M59.6,22.6c0.2,1.4-0.8,3.2-2.2,3c-9.6-1.6-14.4,1-14.6-0.4c-0.2-1.4,6.2-4.6,13.9-4.8
			C58.1,20.4,59.4,21.2,59.6,22.6z"/>
        <path id="brew_P_1_" d="M67.8,22.7c-0.2,1.4,0.8,3.2,2.2,2.9c9.6-1.8,14.4,0.8,14.6-0.6c0.2-1.4-6.3-4.5-13.9-4.6
			C69.3,20.4,68,21.2,67.8,22.7z"/>
	</g>
      <g id="kapelusz_1_">
		<path class="st5" d="M62.4,0.2c0,0,1.6-0.3,3.7,0.5c2.1,0.8,2.6,0.6,3.4,0.6c0.7,0,5.6-0.2,6.3,1.9c0.5,1.6,1,5.9,0.9,7
			c0,0,2.9,0.3,5.5,1.7c2.6,1.4,2.3,1.5,2.3,1.7c0,0,1.5,0,1.4,0.4c0,0.4-3.3,3.1-4,3.5c-0.7,0.4-10,5-15.4,5.6
			c-5.3,0.6-10.8,1-15.2-0.8c-4.3-1.8-6.7-4.7-6.3-8c0.3-3.2,4.2-6.8,6.9-7.5c2.7-0.7,3.6-0.6,4-0.6c0,0,1.1-3.3,2.2-4.2
			C58,2.1,59.8,0.6,62.4,0.2z"/>
        <path class="st6" d="M54.4,13.4c0,0-1.2,2.1,0.3,2.9c0.9,0.5,5.4,3.2,14.8,2.2c9.4-1,7.5-3.4,7.5-3.9c0-0.5,0.3-1-1-0.4
			c-1.3,0.6-9.8,3.3-19.8,0.1C55.4,14.1,54.4,13.4,54.4,13.4z"/>
        <path class="st7" d="M62.4,0.2c0,0,1.6-0.3,3.7,0.5c2.1,0.8,2.6,0.6,3.4,0.6c0.7,0,5.6-0.2,6.3,1.9c0.5,1.6,1,5.9,0.9,7
			c0,0,2.9,0.3,5.5,1.7c2.6,1.4,2.5,1.6,2.5,1.8c0,0,1.3,0,1.2,0.4c0,0.4-3.3,3.1-4,3.5c-0.7,0.4-10,5-15.4,5.6
			c-5.3,0.6-10.8,1-15.2-0.8c-4.3-1.8-6.7-4.7-6.3-8c0.3-3.2,4.2-6.8,6.9-7.5c2.7-0.7,3.6-0.6,4-0.6c0,0,1.1-3.3,2.2-4.2
			C58,2.1,59.8,0.6,62.4,0.2z"/>
        <polygon class="st0" points="55.9,14.7 56.1,15.2 56.6,15.3 56.2,15.6 56.3,16.1 55.8,15.9 55.3,16.1 55.5,15.6 55.1,15.2
			55.6,15.2 		"/>
        <polygon class="st0" points="60.1,15.9 60.3,16.4 60.8,16.5 60.4,16.8 60.5,17.3 60,17.1 59.6,17.3 59.7,16.8 59.3,16.4
			59.8,16.4 		"/>
        <polygon class="st0" points="65.4,16.4 65.6,16.9 66.1,17 65.7,17.3 65.7,17.9 65.3,17.6 64.8,17.8 64.9,17.3 64.6,16.9
			65.1,16.9 		"/>
        <polygon class="st0" points="70.9,16.4 71.1,16.8 71.6,16.9 71.2,17.3 71.3,17.8 70.9,17.5 70.4,17.8 70.5,17.3 70.1,16.9
			70.7,16.8 		"/>
        <polygon class="st0" points="75.4,15 75.6,15.5 76.1,15.5 75.7,15.9 75.7,16.4 75.3,16.1 74.8,16.4 74.9,15.9 74.6,15.5
			75.1,15.4 		"/>
        <path class="st8" d="M54.5,11.3c0,0-8.5,0.4-9.7,3.4c-0.2,0.5,0.6-4.6,4.8-7c0,0,3.1-1.6,6.1-1.4c0.1,0-1.5,6.6-1.5,6.6"/>
        <path class="st8" d="M71.6,1.4c0,0-2.7-0.5-6.9,1.5c-1.3,0.7-4.2,0.1-4.9-0.3c0,0-0.4-0.3,2.1-0.2c2.5,0.1,5.6-0.7,5.9-1.1
			C67.8,1.3,71.3,1.4,71.6,1.4z"/>
        <path class="st8" d="M66.8,8.3c0,0,3.3,3.8,5.5,3.6c2.2-0.2,3.1-1.8,3.2-2.4c0.1-0.6-1,0.1-1.7,0.6c-0.7,0.5-1.3,0.6-2,0.6
			c-0.8-0.1-2.2-0.9-3.1-1.6C66.3,7,66.6,7.9,66.8,8.3z"/>
        <path class="st8" d="M76.8,12.9l-0.7,1.2l-8.6,4.6c0,0-5.9,4.7-11.3,4.4c0,0,3.9,0.7,9.5-1.6c0,0,8-3.6,10-4.6
			c2-0.9,7.2-3,8.1-3.2c0.8-0.2,0.9-0.2,0.5-0.6c-0.3-0.3-3.5-2.3-7.2-2.8c-0.6-0.1-0.5,0.3-0.4,0.4L76.8,12.9z"/>
	</g>
      <g id="krawat_1_">
		<g>
			<path class="st9" d="M66.3,98.6c7.2,11.8,4.4,18.2-5.5,27.1c-5.1,4.6-18.7,12.9-20.6,19.9c-0.5,1.8-2.9,8.9,4.4,13
				c1.5,0.9,3.9,8,3.3,8.5c-0.6,0.5-8.1,0.9-10.9-1.5c-8.3-7.2-8.6-17.8-6.1-24.3c2.5-6.8,7.4-9.3,12.9-13.4
				c10.8-7.8,23.4-15.3,20.4-29.4L66.3,98.6z"/>
		</g>
        <path class="st9" d="M51.5,85.5c0,0,5.1,5.3,8.2,5.7c3.1,0.3,2.2,9.1,2.2,9.1s-6.6,1-10.6-0.4C51.3,99.8,49.3,94.7,51.5,85.5z"/>
        <path class="st9" d="M79.6,85.8c0,0-4.1,5.3-7.2,5.7c-3.1,0.3-3.8,8.6-3.8,8.6s7,1.8,11.1,0C79.9,100.1,81.8,95,79.6,85.8z"/>
        <path class="st10" d="M66.2,95.4c-0.1,2-2.5,3.9-5.3,3.8c-5.2-0.2-2.4-1.5-2.3-3.5c0.1-2-6.3-5.1,2.8-4.2
			C64.2,91.8,66.4,93.4,66.2,95.4z"/>
        <path class="st10" d="M65.2,95.8c0.1,2,2.5,3.9,5.3,3.8c5.2-0.2,2.4-1.5,2.3-3.5c-0.1-2,6.3-5.1-2.8-4.2
			C67.2,92.2,65,93.8,65.2,95.8z"/>
        <path class="st10" d="M65.8,99.3c-0.6,0.3-0.7,2.9-0.2,5.6c0.9,5.1,0.8,2.3,1.4,1.9c0.6-0.3,2.5,5.7,0.7-3.2
			C67.2,101,66.4,99,65.8,99.3z"/>
        <path class="st9" d="M72.4,91.7c0,3.6-2.7,12-6.5,12c-3.8,0-7.3-8.3-7.3-12c0-3.6,3-3.1,6.8-3.1C69.3,88.6,72.4,88.1,72.4,91.7z"
        />
	</g>
</g>
</svg>

</template>

<script>
export default {
name: "MrZip"
}
</script>

<style scoped>
.st0{fill:#F1F6F6;}
.st1{fill:#E1EDED;}
.st2{fill:#A5B2B2;}
.st3{fill:none;stroke:#575756;stroke-width:2.8347;stroke-linecap:round;stroke-miterlimit:10;}
.st4{fill:#FFFFFF;}
.st5{fill:#683C11;}
.st6{opacity:0.4;}
.st7{fill:none;stroke:#432918;stroke-width:0.2655;stroke-miterlimit:10;}
.st8{opacity:0.3;}
.st9{fill:#E94E1B;}
.st10{fill:#BE1622;}
</style>