<template>
  <tr
    :data-task_id="t.uuid"
    :class="{
      disabled_row: t.status === 'qa' || t.status === 'corporate_client_qa',
    }"
  >
    <td class="btn_container">
      <div
          v-if="$store.state.user && task.employee_id === $store.state.user.id && task.unapproved === 0"
          class="in_progress_btn active"
      >
        <button v-if="task.in_work === true" @click="stopTask">
          <i class="icon-Stop" />
        </button>
        <button v-else @click="startTask"><i class="icon-Play" /></button>
      </div>
    </td>
    <td v-if="$store.getters.isAdmin">
      <i class="icon-Drag"></i>
    </td>
    <td v-if="$store.getters.isAdmin">
      <div class="dropdown">
        <i
          class="icon-Forward cursor-pointer"
          id="userForward"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <ul class="dropdown-menu scrollable-menu" aria-labelledby="userForward">
          <li v-for="(u, index) in $store.state.users" :key="index">
            <a
              class="dropdown-item cursor-pointer"
              @click="handleEmployeeChange(u.id)"
              >{{ u.name }}</a
            >
          </li>
        </ul>
      </div>
    </td>
    <td>
      <div v-if="t.unapproved" class="unapproved">
        <button v-if="$store.getters.isAdmin" @click="approve">akceptuj</button>
        <span v-else-if="$store.getters.isAccount" class="unapproved-label"
          >niezaakceptowane</span
        >
      </div>
    </td>
    <td @click="openTask">
      <select
        v-if="editMode && $store.getters.isAdmin"
        v-model="t.corporate_client_id"
      >
        <option
          :value="cc.id"
          v-for="cc in $store.state.corporateClients"
          :key="'cc_' + cc.id"
        >
          {{ cc.name }}
        </option>
      </select>
      <span v-else>{{ t.corporate_client ? t.corporate_client.name : '' }}</span>
    </td>
    <td v-if="task.ref_num !== null" @click="openTask">
      {{ task.created_at.substring(2, 4) + "_" + task.ref_num }}
    </td>
    <td v-else @click="openTask">-</td>
    <td @click="openTask">
      <textarea
        class="form-control"
        v-if="editMode"
        v-model="t.topic"
      ></textarea>
      <span v-else>{{ t.topic }}</span>
    </td>
    <td @click="openTask" v-if="$store.getters.isAdmin">
      <div>
        <span v-if="t.estimation" class="d-inline-block" :class="{ 'negative-time': isTimeNegative(t.remainingTime) }">
          {{ t.remainingTime || '--:--' }}
        </span>
        <span v-else>--:--</span>
      </div>
    </td>
    <td @click="openTask">
      <select v-if="editMode && $store.getters.isAdmin" v-model="t.account_id">
        <option
          :value="account.id"
          v-for="account in $store.getters.accountUsers"
          :key="'account_' + account.id"
        >
          {{ account.name }}
        </option>
      </select>
      <span v-else>{{ t.account ? t.account.name : "" }}</span>
    </td>
    <td @click="openTask">
      <select v-if="editMode" class="form-control" v-model="t.status">
        <option value="">-</option>
        <option value="corporate_client_qa">Po stronie klienta</option>
        <option value="qa">Po stronie akanta</option>
        <option value="required_more_data">Brak materiałów</option>
        <option value="pending">Korekta</option>
        <option value="done">Zakończone</option>
      </select>
      <div v-else>
        <task-status-label :status="t.status" :error="isQAOverdue(task)" gif/>
        <task-status-label v-if="t.in_work" status="in_work" gif/>
        <task-status-label v-else-if="t.work_logs.length > 0" gif status="pause" />
      </div>
    </td>
    <td @click="openTask">
      <div class="position-relative">
        <span
          v-if="t.description"
          class="d-inline-block"
          data-bs-toggle="popover"
          data-bs-trigger="hover"
          :data-bs-content="t.description"
          ref="comment"
          ><i :class="{ active: t.description }" class="icon-Comment"></i
        ></span>
        <i v-else class="icon-Comment"></i>
      </div>
    </td>
    <td @click="openTask">
      <div>
        <span
          v-if="t.deadline"
          class="d-inline-block"
          data-bs-toggle="popover"
          data-bs-trigger="hover"
          :data-bs-content="t.deadline.replace('T', ' ')"
          ref="deadline"
          ><i
            :class="[{ active: t.deadline }, { 'text-danger': lateness }, { 'text-warning': checkDeadline }]"
            class="icon-Calendar"
          ></i
        ></span>
        <i v-else class="icon-Calendar"></i>
      </div>
    </td>
    <td>
      <i
        v-if="
          $store.getters.isAdmin ||
            ($store.getters.isAccount &&
              t.account_id === $store.state.user.id &&
              t.status === 'qa')
        "
        class="icon-Verificate cursor-pointer"
        @click="handleStatusChange('done')"
      ></i>
    </td>
    <td class="btn_container">
      <div
          v-if="
          $store.state.user &&
          task.status !== 'qa' &&
          task.employee_id === $store.state.user.id
        "
          :class="{ active:  true }"
          class="qa_btn"
      >
        <button @click="setStatusQa"><i class="icon-Check" /></button>
      </div>
    </td>
  </tr>
</template>

<script>
import api from "../api";
import { mapState } from 'vuex';
import TaskStatusLabel from "@/components/TaskStatusLabel";
const bootstrap = require("bootstrap");
import moment from 'moment';

export default {
  name: "TaskRow",
  components: { TaskStatusLabel },
  props: {
    task: Object,
  },
  data() {
    return {
      editMode: false,
      editComment: false,
      editDeadline: false,
    };
  },
  mounted() {
    if (this.$refs.comment) {
      new bootstrap.Popover(this.$refs.comment, {});
    }
    if (this.$refs.deadline) {
      new bootstrap.Popover(this.$refs.deadline, {});
    }
    this.updateRemainingTimes();
    setInterval(() => {
      this.updateRemainingTimes();
    }, 60000);
  },
  computed: {
    ...mapState(['tasks']),
    t() {
      return this.tasks.find(t => t.uuid === this.task.uuid);
    },
    filteredUsers: function() {
      return this.$store.state.filteredUsers;
    },
    lateness: function() {
      if (this.t.deadline) return new Date(this.t.deadline.replace(" ","T")) - Date.now() < 0;
      else return false;
    },
    checkDeadline: function () {
      if(!this.task.deadline) {
        return false;
      }
      let diff = (new Date(this.task.deadline.replace(" ","T")) - Date.now())/ 3600000;
      if (this.task.deadline)
        return diff <= 12 && diff > 0;
      else return false;
    },
  },
  watch: {
    t: {
      handler() {
        this.updateRemainingTimes();
      },
      deep: true,
    }
  },
  methods: {
    startTask() {
      api.startTask(this.task.uuid, this.$store.state.user.id).then((response) => {
        this.$store.commit("TASK_UPDATED_WORK_LOG", response.data);
        this.t = { ...this.task };
      });
    },
    stopTask() {
      api.stopTask(this.task.uuid, this.$store.state.user.id).then((response) => {
        this.$store.commit("TASK_UPDATED_WORK_LOG", response.data);
        this.t = { ...this.task };
      });
    },
    setStatusQa() {
      api.setStatusQa(this.task.uuid).then((response) => {
        this.$store.commit("TASK_UPDATED", response.data);
        this.t = { ...this.task };
      });
    },
    openTask() {
      this.$emit("openTask");
    },
    handleEmployeeChange(id) {
      this.t.employee_id = id;
      this.updateTask();
    },
    updateTask() {
      api
        .updateTask(this.t.uuid, this.t)
        .then((response) => {
          this.$store.commit("TASK_UPDATED", response.data);
        })
        .catch((error) => {
          console.error(error);
          this.t = this.task;
        });
    },
    approve() {
      api.approveTask(this.t.uuid).then((response) => {
        this.$store.commit("TASK_UPDATED", response.data);
      });
    },
    isQAOverdue(task) {
      if (
        this.$store.state.user.id === task.account_id ||
        this.$store.state.user.type === "admin"
      ) {
        let overdueDate = new Date(task.updated_at);
        overdueDate.setDate(overdueDate.getDate() + 1);
        overdueDate = overdueDate.getTime();
        return Date.now() > overdueDate;
      }
      return false;
    },
    handleStatusChange(status) {
      this.t.status = status;
      this.updateTask();
    },
    getTotalLoggedTime(workLogs) {
      return workLogs.reduce((total, log) => {
        if (!log.end) {
          return total;
        }
        const startTime = moment(log.start);
        const endTime = moment(log.end);
        const duration = moment.duration(endTime.diff(startTime));
        const roundedDuration = Math.ceil(duration.asMinutes());
        return total + roundedDuration;
      }, 0);
    },
    getActiveLogTime(workLogs) {
      const activeLog = workLogs.find(log => !log.end);
      if (!activeLog) {
        return 0;
      }
      const startTime = moment(activeLog.start);
      const currentTime = moment();
      const duration = moment.duration(currentTime.diff(startTime));
      const roundedDuration = Math.ceil(duration.asMinutes());
      return roundedDuration;
    },
    getRemainingTime(estimation, workLogs) {
      if(estimation && workLogs) {
        const [estHours, estMinutes] = estimation.split(':').map(Number);
        const estTotalMinutes = estHours * 60 + estMinutes;
        const totalLoggedMinutes = this.getTotalLoggedTime(workLogs);
        const activeLogMinutes = this.getActiveLogTime(workLogs);
        const remainingMinutes = estTotalMinutes - totalLoggedMinutes - activeLogMinutes;

        const hours = Math.floor(Math.abs(remainingMinutes) / 60).toString().padStart(2, '0');
        const minutes = (Math.abs(remainingMinutes) % 60).toString().padStart(2, '0');
        const sign = remainingMinutes < 0 ? '-' : '';
        return `${sign}${hours}:${minutes}`;
      }
      return '--:--';
    },
    updateRemainingTimes() {
      this.t.remainingTime = this.getRemainingTime(this.t.estimation, this.t.work_logs);
    },
    isTimeNegative(time) {
      if(time) {
        return time.startsWith('-');
      } else {
        return false
      }
    }
  },
};
</script>
<!--<i class="icon-Check"/>-->
<style scoped lang="scss">
@import "../assets/scss/variables";
.scrollable-menu {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
}
.unapproved {
  padding: 0;
  display: flex;
  align-content: center;
  .unapproved-label {
    background-color: $warning;
    color: $black;
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 4px;
  }
  button {
    border: none;
    background-color: $primary;
    color: $white;
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 4px;
    i {
      color: $white;
      margin-left: 4px;
    }
  }
}
.comment-input {
  position: absolute;
  top: 0;
  left: -8px;
  filter: drop-shadow(0 4px 10px rgba(#d3dde8, 80%));
  transform: translateX(-100%);
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
}

.icon-Drag {
  cursor: move;
}
.gu-transit {
  filter: drop-shadow(16px 0 32px rgba(0, 0, 0, 0.5));
  background-color: #f2f2f2;
}
tr {
  cursor: pointer;
  td {
    &:nth-child(3),
    &:nth-child(5) {
      font-weight: 600;
    }
  }
}
i {
  font-size: 16px;
  color: #c8c9cc;
  &.active {
    color: $secondary;
  }

}
.table-striped > tbody > .disabled_row:hover td{
  color: #212529 !important;
}


.btn_container {
  padding: 0 !important;
  position: relative;
  .qa_btn {
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }
  .in_progress_btn {
    display: none !important;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
  }
}
tr:hover {
  .btn_container {
    .in_progress_btn {
      display: flex !important;
    }
  }
}
.qa_btn {
  display: none;
  button {
    width: 0;
  }
  &.active {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0;
    button {
      transition: width ease-out 200ms;
      border: none;
      height: 100%;
      background-color: #c8c9cc;
      padding: 12px 8px;
      width: 45px;
      border-radius: 0 5px 5px 0;
      &:hover {
        background-color: $success;
        width: 65px;
      }
      i {
        color: $white;
      }
    }
  }
}
.in_progress_btn {
  display: none;
  button {
    width: 0;
  }
  &.active {
    display: flex;
    position: absolute;
    align-items: center;
    padding: 0;
    button {
      transition: width ease-out 200ms;
      border: none;
      height: 100%;
      background-color: #c8c9cc;
      padding: 12px 8px;
      width: 45px;
      border-radius: 5px 0 0 5px;
      &:hover {
        background-color: $secondary;
      }
      i {
        color: $white;
      }
    }
  }
}
tr {
  position: relative;
  cursor: pointer;
  td {
    &:nth-child(1),
    &:nth-child(3) {
      font-weight: 600;
    }
  }
}
i {
  font-size: 16px;
  color: #c8c9cc;
  &.active {
    color: #4d71ec;
  }
}
.negative-time {
  color: $danger;
}
</style>
