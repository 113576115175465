<template>
  <app-wrapper>
    <page-header title="Lista pracowników"></page-header>

    <div class="container my-5">
      <h5 class="mb-4">Nowy pracownik</h5>
      <div class="row">
          <div class="col">
            <label>Imię nazwisko</label>
            <input class="form-control" placeholder="Imię nazwisko" v-model="newUser.name"/>
          </div>
          <div class="col">
            <label>Email</label>
            <input class="form-control" autocomplete="off" placeholder="Email" v-model="newUser.email"/>
          </div>
          <div class="col">
            <label>Typ</label>
            <select v-model="newUser.type" class="form-control">
              <option v-for="(t, index) in types" :key="index" :value="t">{{t}}</option>
            </select>
          </div>
          <div class="col">
            <label>Hasło</label>
            <input class="form-control" autocomplete="off" type="password" placeholder="Hasło" v-model="newUser.password"/>
          </div>
      </div>
      <div class="row my-3">
        <div class="col">
          <label>Groupy</label>
          <div class="row height">
            <div class="col" v-for="(g, index) in groups" :key="index">
              <div class="d-flex h-100 align-items-center">
                <input type="checkbox" :id="g.id" :value="g.id" v-model="newUser.selectedGroups"/>
                <label class="px-3 py-1">{{getGroupLabel(g.name)}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 justify-content-end">
        <div class="col col-auto">
          <button class="btn btn-primary" @click="handleCreateUser">Dodaj</button>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <table class="table table-striped gs-4 gy-4">
            <thead>
              <tr>
                <th>Imię, nazwisko</th>
                <th>Typ</th>
                <th>Email</th>
                <th>Grupy</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="users && users.length > 0">
              <tr v-for="(u,index) in users" :key="u.id">
                <td>{{ u.name }}</td>
                <td>{{ u.type}}</td>
                <td>{{ u.email}}</td>
                <td>{{ u.groups.map(g => getGroupLabel(g.name)).reduce((n, m, index) => n + (index === 0 ? "" : ", ") + m, "")}}</td>
                <td><i @click="openDialog(index)" class="icon-Pencil"/></td>
                <td><i @click="openDeleteDialog(u.id)" class="icon-Delete"/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="isDialogOpen" class="modal dialog-background show">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edytuj użytkownika</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <label>Imię nazwisko</label>
                <input class="form-control" placeholder="Imię nazwisko" v-model="editUser.name"/>
              </div>
              <div class="col">
                <label>Email</label>
                <input class="form-control" placeholder="Email" v-model="editUser.email"/>
              </div>
            </div>
            <div class="row my-3">
              <div class="col">
                <label>Typ</label>
                <select v-model="editUser.type" class="form-control">
                  <option v-for="(t, index) in types" :key="index" :value="t">{{t}}</option>
                </select>
              </div>
              <div class="col">
                <label>Hasło</label>
                <input class="form-control" type="password" placeholder="Hasło" v-model="editUser.password"/>
              </div>
            </div>
            <div class="row mt-3 mb-5">
              <div class="col mb-5">
                <label>Groupy</label>
                <div class="row height">
                  <div class="col-4" v-for="(g, index) in groups" :key="index">
                    <div class="d-flex h-100 align-items-center">
                      <input type="checkbox" :id="g.id" :value="g.id" v-model="editUser.selectedGroups"/>
                      <label class="px-3 py-1">{{getGroupLabel(g.name)}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="updateUser">Zapisz</button>
            <button class="btn btn-secondary" @click="openDialog">Zamknij</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDeleteDialogOpen" class="modal dialog-background show">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Czy napewno chcesz usunąć użytkownika?</h5>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="deleteUser">Usuń</button>
            <button class="btn btn-secondary" @click="openDeleteDialog">Zamknij</button>
          </div>
        </div>
      </div>
    </div>
  </app-wrapper>
</template>

<script>
import AppWrapper from "@/components/AppWrapper";
import PageHeader from "@/components/PageHeader";
import isEmail from "validator/lib/isEmail";
import api from '../api'
import { mapActions } from 'vuex';

export default {
  name: "Users",
  components: {PageHeader, AppWrapper},
  data() {
    return {
      newUser: {
        name: null,
        email: "",
        type: "user",
        selectedGroups: [],
        password: ""
      },
      editUser: {
        id: null,
        name: null,
        email: "",
        type: "user",
        selectedGroups: [],
        password: ""
      },
      types: ["admin","user"],
      isDialogOpen: false,
      isDeleteDialogOpen: false,
      deleteUserId: null,
    }
  },
  computed: {
    users() {
      return this.$store.state.users.filter(u => u.id !== 3);
    },
    groups() {
      return this.$store.getters.groups;
    },
  },
  methods: {
    ...mapActions({
      snack: 'snack/snack'
    }),
    getGroupLabel(group) {
      const groupLabels = {graphic_creative: 'grafik kreatywny', graphic_dtp: 'grafik dtp', graphic_ui: 'grafik UI', ux: 'UX', it: 'IT', dev_frontend: 'programista frontend', dev_backend: 'programista backend'};
      const groupLabel = groupLabels[group];
      return groupLabel ? groupLabel : group;
    },
    deleteUser () {
      api.deleteUser(this.deleteUserId)
          .then((response) => {
            this.$store.state.users = response.data
            this.isDeleteDialogOpen = false
            this.snack({text: "Użytkownik został usunięty", severity: "info"});
          })
          .catch((error) => {
            const msg = error.response.data.errors ? Object.values(error.response.data.errors)[0][0] : "Wystąpił nieoczekiwany błąd"
            console.log(error.response);
            this.snack({text: msg, severity: "warning"});
          })
    },
    updateUser () {
      if(!this.editUser.name) {
        this.snack({text: "Podaj imię", severity: "warning"});
        return;
      }
      if(!isEmail(this.editUser.email)) {
        this.snack({text: "Podaj poprawny email", severity: "warning"});
        return;
      }
      api.updateUser(this.editUser)
          .then(response => {
            this.$store.state.users = response.data
            this.snack({text: "Dane użytkownika zostały zaktualizowane", severity: "info"});
            this.editUser = {
              name: null,
              email: "",
              type: "user",
              selectedGroups: [],
              password: ""
            }
            this.isDialogOpen = false
          })
          .catch((error) => {
            const msg = error.response.data.errors ? Object.values(error.response.data.errors)[0][0] : "Wystąpił nieoczekiwany błąd"
            console.log(error.response);
            this.snack({text: msg, severity: "warning"});
          })
    },
    openDeleteDialog (userId) {
      this.isDeleteDialogOpen = !this.isDeleteDialogOpen;
      this.deleteUserId = userId;
    },
    openDialog (userIndex) {
      this.isDialogOpen = !this.isDialogOpen;
      this.editUser = {
        id: this.users[userIndex].id,
        name: this.users[userIndex].name,
        email: this.users[userIndex].email,
        type: this.users[userIndex].type,
        selectedGroups: this.users[userIndex].groups.map(g => g.id),
      }
    },
    handleCreateUser() {
      if(!this.newUser.name) {
        this.snack({text: "Podaj imię", severity: "warning"});
        return;
      }
      if(!isEmail(this.newUser.email)) {
        this.snack({text: "Podaj poprawny email", severity: "warning"});
        return;
      }
      api.createUser(this.newUser)
        .then(response => {
          this.$store.state.users = response.data
          this.snack({text: "Utworzono nowego użytkownika", severity: "info"});
          this.newUser = {
            name: null,
            email: "",
            type: "user",
            selectedGroups: [],
            password: ""
          }
        })
        .catch((error) => {
          const msg = error.response.data.errors ? Object.values(error.response.data.errors)[0][0] : "Wystąpił nieoczekiwany błąd"
          console.log(error.response);
          this.snack({text: msg, severity: "warning"});
        })
    }
  }
}
</script>

<style scoped>
 select, .height {
   width: 100%;
   height: 31px;
 }
 input[type=checkbox] {
   position: relative;
   cursor: pointer;
 }
 input[type=checkbox]:before {
   content: "";
   display: block;
   position: absolute;
   width: 16px;
   height: 16px;
   top: 0;
   left: 0;
   border: 2px solid #555555;
   border-radius: 3px;
   background-color: white;
 }
 input[type=checkbox]:checked:after {
   content: "";
   display: block;
   width: 5px;
   height: 10px;
   border: solid black;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   position: absolute;
   top: 2px;
   left: 6px;
 }
i {
  cursor: pointer;
  padding: 10px;
}
i:hover {
  background: lightgray;
  border-radius: 50%;
}
.dialog-background {
  background-color: #21252944;
  display: block;
}

</style>
