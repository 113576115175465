<template>
  <div class="authorization_view">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3 text-center">
          <img src="../assets/media/logo_white_text.svg" class="logo">
        </div>
        <div class="form-wrapper col-12 text-center mt-4">
          <form class="form" @submit.prevent="login">
            <div>
              <label class="mb-2" for="email">Email:</label>
              <input class="form-control" type="email" placeholder="Email" autocomplete="on" v-model="email" required>
            </div>
            <div>
              <label class="mb-2" for="password">Hasło:</label>
              <input class="form-control" type="password" placeholder="Hasło" v-model="password" required>
            </div>
            <button class="btn btn-primary mt-3 mb-3" type="submit">
              Zaloguj się
              <i class="icon-Arrow-Right" />
            </button>
          </form>
          <form @submit.prevent="sendResetLink" class="mb-5">
            <div class="mt-4">
              <p>Każdemu zdarza się zapomnieć hasła ;)</p>
              <button class="btn btn-primary">reset hasła</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api';
import {mapActions} from "vuex";
// import UiButton from "@/components/UiButton";

export default {
  name: "Authorization",
  components: {
    // UiButton,
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  created() {

  },
  methods: {
    ...mapActions({
      snack: 'snack/snack'
    }),
    async login() {
      try {
        const response = await api.login(this.email, this.password);
        this.$store.dispatch('setUser', response.data);
        await this.$router.push('/home');
      } catch (error) {
        console.error('Login error:', error);
        this.snack({text: "Niepoprawny email lub hasło", severity: "error"});
      }
    },
    async sendResetLink() {
      if(this.email === "") {
        this.snack({text: "Wprowadź email", severity: "error"});
        return
      }
      try {
        await api.sendResetLink(this.email);
        this.snack({text: "Sprawdź maila", severity: "success"});
      } catch (error) {
        console.error('Error sending reset link:', error);
        this.snack({text: "Niepoprawny email lub hasło", severity: "error"});
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.btn {
  font-size: 16px;
  width: fit-content;
  align-self: center;
  text-transform: uppercase;
  background-color: #fff;
  color: $primary;
  font-weight: 600;

  &:hover {
    background-color: $gray-400;
  }

  & i {
    font-size: 12px;
    color: $primary;
  }
}
.authorization_view {
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/media/home_background.svg");
  background-color: $primary;
  color: $white;
  .logo{
    max-width: 90px;
    object-fit: cover;
    margin-top: 60px;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 460px;
  width: 100%;

  & label,
  & input {
    font-size: 16px;
  }
}

</style>
